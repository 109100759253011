
































import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {StoreActions} from '../config/store';
import CurrentMonthInvoicing from './partial/current-month-invoicing.vue';
import MenuBlockContainer from '../component/menu-blocks/list.vue';
import MenuBlock from '../component/menu-blocks/item.vue';
import TabContainer from '../component/tabs/container.vue';
import TabPage from '../component/tabs/page.vue';

@Component({components: {MenuBlock, MenuBlockContainer, TabPage, TabContainer, CurrentMonthInvoicing}})
export default class Main extends Vue {
    
    // View permissions
    PERM_CAN_SEE_ORDER_PRICES = 'maintenance_general.can_see_order_prices_in_client'

    get canViewMonthTotalInvoicing() {
        return this.$app.hasPermission(this.PERM_CAN_SEE_ORDER_PRICES) && this.$app.user.isAuthenticated; 
    }
}
