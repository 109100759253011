






import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class BulmaIcon extends Vue {        
    @Prop({type: String})
    name: string;
}
