













import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {TabBrowseDirection} from '.';
@Component
export default class TabPage extends Vue
{
    @Prop({type: Boolean, default: false})
    isActive: boolean;

    isVisible = false;
    direction = TabBrowseDirection.FORWARD;

    data()
    {
        return {
            isVisible: this.isActive
        }
    }
}
