import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import Main from '../view/main.vue';
import AuthenticationSignIn from '../view/authentication/sign-in.vue';
import AuthenticationSignOut from '../view/authentication/sign-out.vue';
import Synchronize from '../view/synchronization/synchronize.vue';
import SyncQueue from '../view/synchronization/queue.vue';
import { AuthRouteConfig, RouteAuthentication } from "../../core/plugin/app/authentication";
import Vue from "vue";
import AuthenticationComplete from "../view/authentication/complete.vue";


['push','replace'].forEach(method => {
    const originalMethod = VueRouter.prototype[method];
    VueRouter.prototype[method] = function m(location) {
      return originalMethod.call(this, location).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          return error;
        }
      })
    };
  });

Vue.use(VueRouter);

const MessagesView = () => import(/* webpackChunkName: "messages" */ '../view/messages.vue');
const AboutView = () => import(/* webpackChunkName: "about" */ '../view/about.vue');
const SearchCustomerEquipmentView = () => import(/* webpackChunkName: "cu-eq-search" */ '../view/customer-equipment/search.vue');
const CustomerEquipmentDetail = () => import(/* webpackChunkName: "cu-eq-detail" */ '../view/customer-equipment/detail.vue');
const NearByOrdersView = () => import(/* webpackChunkName: "order-near-by" */ '../view/order/near-by.vue');
const OrderListView = () => import(/* webpackChunkName: "order-list" */ '../view/order/list.vue');
const OrderDetailView = () => import(/* webpackChunkName: "order-detail" */ '../view/order/detail.vue');
const ChangeLocationView = () => import(/*webpackChunkName: "change-location" */ '../view/change-location.vue');
const CheckItemPage = () => import(/*webpackChunkName: "check-item" */ '../view/check/item.vue');

// Vue-router
// TODO: use history api instead of hash based routing (VP)
const router = new VueRouter({
    //mode: 'history',
    routes: [
        { path: '/', component: Main, name: 'home' },
        { path: '/about', component: AboutView, name: 'about' },
        { path: '/messages', component: MessagesView, name: 'messages' },

        { path: '/order/list',  component: OrderListView,  name: 'order:list', },
        { path: '/order/:id/detail',  component: OrderDetailView,  name: 'order:detail', },
        { path: '/order/:id/detail/:tab',  component: OrderDetailView,  name: 'order:detailTab', },
        { path: '/order/near-by/',  component: NearByOrdersView,  name: 'order:nearBy', },
        { path: '/order/near-by/:tab',  component: NearByOrdersView,  name: 'order:nearBy:tab', },

        { path: '/customer-equipment/search',  component: SearchCustomerEquipmentView,  name: 'customerEquipment:search', },
        { path: '/customer-equipment/:id/detail/',  component: CustomerEquipmentDetail,  name: 'customerEquipment:detail', },
        { path: '/customer-equipment/:id/detail/:tab',  component: CustomerEquipmentDetail,  name: 'customerEquipment:detailTab', },

        { path: '/change-location/', component: ChangeLocationView, name: 'changeLocation'},

        <AuthRouteConfig>{
            path: '/synchronize',  component: Synchronize,  name: 'synchronize', 
            meta: { authentication: { type: RouteAuthentication.OnlyAuthenticated }}
        },
        <AuthRouteConfig>{
            path: '/synchronize/queue',  component: SyncQueue,  name: 'synchronize:Queue', 
            meta: { authentication: { type: RouteAuthentication.OnlyAuthenticated }}
        },

        <AuthRouteConfig>{
            path: '/authentication/sign-in', name:'auth-sign-in',  beforeEnter: () => {
              // Use current url as a base for the login urls
              const currentUrl = new URL(location.href);

              // Remove "-client" from the yrl
              const loginHost = currentUrl.host.replace('-client', '');

              // Client login flow url
              const clientLoginUrl = `${currentUrl.protocol}//${currentUrl.host}/#/authentication/complete`;
              
              // TC Login flow url
              const loginUrl = `${currentUrl.protocol}//${loginHost}/login/?client=${encodeURIComponent(clientLoginUrl)}`;

              // Start login flow by redirecting to TC login
              location.href = loginUrl;
            
            }, 
            meta: { authentication: { type: RouteAuthentication.OnlyAnonymous }}
        },

        <AuthRouteConfig>{
          path: '/authentication/complete', name:'auth-complete',  component: AuthenticationComplete, 
          meta: { authentication: { type: RouteAuthentication.OnlyAnonymous }}
        },

        <AuthRouteConfig>{ 
            path: '/authentication/sign-in/reset-password', component: AuthenticationSignIn, name: 'auth-sign-in-reset-password',
            meta: { authentication: { type: RouteAuthentication.OnlyAnonymous }, showModal: true}
        },

        <AuthRouteConfig>{
            path: '/authentication/sign-out', component: AuthenticationSignOut, name: 'auth-sign-out',
            // Default but explicitly defined for clarity
            meta: { authentication: { type: RouteAuthentication.OnlyAuthenticated }}
        },

        { path: '/check-item/', component: CheckItemPage, name: 'changeLocation'},

        {path: '*', redirect: '/'}
    ]
});


export default router;