export const format = (str: string, args?: StringFormatArgs, autoEscape = true) =>
{
    if (args != null)
    {
        return str.replace(/\{([a-zA-Z0-9_]+)\}/g, (match, key) => 
        {
            if (args.hasOwnProperty(key))
            {
                let value = args[key];
                if (value != null)
                {
                    let strValue = value.toString();
                    return autoEscape ? escape(strValue) : strValue;
                }
                else
                {
                    return '';
                }
            } 
            else 
            {
                return match;
            }
        });
    }
    else
    {
        return str;
    }
}

export const escape = (text: string): string =>
{
    var entityMap: {[key: string]: string} = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
      };
      
    return String(text).replace(/[&<>"'`=\/]/g, function (s) {
        return entityMap[s];
    });
};


export interface StringFormatArgs
{
    [key: string]: number | string;
}

export const repeat = (text: string, count: number) =>
{
    let result = '';
    for(let i = 0; i < count; i++)
    {
        result += text;
    }

    return result;
}