













import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class LoadingSpinner extends Vue 
{        
    @Prop({type: Number, default: 100})
    delay: number;

    isVisible = false;
    delayedVisibilityTimeoutHandle: number;

    mounted()
    {
        if (this.delay == 0)
        {
            this.isVisible = true;
        }
        else
        {            
            this.delayedVisibilityTimeoutHandle = window.setTimeout(() =>
            {
                this.isVisible = true;
            }, this.delay);
        }
    }

    destroyed()
    {
        window.clearTimeout(this.delayedVisibilityTimeoutHandle);
    }
}
