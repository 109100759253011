var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "view paginated-list",
      class: { "has-page-navigation-visible": _vm.isPageNavigationVisible }
    },
    [
      _c(
        "div",
        { staticClass: "view-header" },
        [
          _c("page-navigation", {
            ref: "pageNavigation",
            attrs: {
              isLoading: _vm.isLoading,
              pageCount: _vm.pageCount,
              isPageNavigationVisible: _vm.isPageNavigationVisible,
              canMoveToPreviousPage: _vm.canMoveToPreviousPage,
              canMoveToNextPage: _vm.canMoveToNextPage,
              pageIndex: _vm.pageIndex
            },
            on: {
              previousPage: _vm.moveToPreviousPage,
              nextPage: _vm.moveToNextPage,
              jumpToPage: _vm.jumpToPage
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "itemsContainer",
          staticClass: "view-content items-container",
          class: [_vm.itemContainerClassName],
          style: [_vm.itemContainerStyles],
          on: { scroll: _vm.onListScroll }
        },
        [
          _vm._t("before-items"),
          _vm._v(" "),
          _vm._l(_vm.pageItems, function(item, index) {
            return [
              _vm._t(
                "item",
                null,
                { index: index },
                { item: item, key: _vm.itemKeyGetter(item, index) }
              )
            ]
          }),
          _vm._v(" "),
          _vm._t("after-items"),
          _vm._v(" "),
          _vm.pageCount > 1
            ? _c(
                "button",
                {
                  staticClass: "button footer-jump-to-next-page",
                  attrs: { type: "button", disabled: !_vm.canMoveToNextPage },
                  on: { click: _vm.moveToNextPage }
                },
                [
                  _c("bulma-icon", { attrs: { name: "fas fa-arrow-right" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$ugettext("Show next page")))])
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }