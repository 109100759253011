











































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BulmaFormField from '../../component/bulma/form/field/base.vue';
import BulmaEmailField from '../../component/bulma/form/field/email.vue';
import BulmaPasswordField from '../../component/bulma/form/field/password.vue';
import ModalCard from '../../component/bulma/modal-card.vue';
import BulmaButton from '../../component/bulma/button.vue';
import { AttributeType, CurrentUser } from '../../../core/database/models/attribute';

@Component({components: {BulmaButton}})
export default class Synchronization extends Vue 
{
    $refs: Vue['$refs'] & {
        
    }

    startSync()
    {
        this.$app.synchronizer.pull().then(() =>
        {
            
        });
    }
    
    resetClient()
    {
        indexedDB.deleteDatabase(this.$app.config.clientsync.databaseName);
        window.location.reload();
    }
    
    clearQueue()
    {
        this.$app.db.SyncMessage.clear().then(() =>
        {
            window.location.reload();
        });
    }

    showQueue() {
        this.$router.push({
            name: 'synchronize:Queue'
        })
    }
}
