













import Navbar from './partial/navbar.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import ServiceWorkerNotifier from './partial/service-worker-notifier.vue';

@Component({components: {Navbar, ServiceWorkerNotifier}})
export default class Shell extends Vue {
    
    mounted()
    {

    }
}
