import { OfflineError, ServerError } from "./errors";
import { XmlHttpRequestReadyState } from "../enum/request-readystate";
import Fetch from "./fetch";
import AppPlugin from "../plugin/app";
import { Diagnostics, sendErrorDiagnostics, DEFAULT_DELAY } from "../diagnostics";
import { jsonStringifyProperties } from "../helper/object";

export type TCApiBackend = 'public' | 'private';

export class TCApi
{
    public static DefaultBackend = 'private';
    public static DefaultTimeout = 30000;

    private app: AppPlugin;

    constructor(app: AppPlugin) 
    {
        this.app = app;
    }

    public async call<TResult extends TCApiResponse = TCApiResponse, TData extends Object = Object>(method: string, options?: TCApiCallOptions<TData>): Promise<TResult>
    {
        // Options
        let backend = options != null && options.backend != null ? options.backend : TCApi.DefaultBackend;
        let authToken = options != null && options.authToken != null ? options.authToken : undefined;
        const args = options != null && options.data != null ? options.data : {};
        let timeout = options != null && options.timeout != null ? options.timeout : TCApi.DefaultTimeout;
        let forceIncludeToken = options != null && options.forceIncludeToken != null ? options.forceIncludeToken : false;

        if ((forceIncludeToken || backend == 'private') && authToken == null) {
            authToken = this.app.userData.token as string;
        }

        // Derived options
        const url =`${this.app.config.urls.trackingcloud}/internal/api/${backend}/?_=${Date.now()}`;
        const data = JSON.stringify({ method, args });

        const headers = <HeadersInit>{
            'X-Requested-With': 'XMLHttpRequest',
            ...authToken != null && {'X-TC-TOKEN': authToken}
        };
        
        let response: Response;

        try {
            response = await Fetch.fetch(url, {
                body: data,
                method: 'POST',
                headers,
                timeout: 0,
            });
            
            if (response.status != 200) {
                let error = `Unsupported status code ${response.status} (expected 200)`;
                console.error(`[TCApi]: ${error}`);
                throw new ServerError(response.status, error);
            }

            return await response.json();

        } catch (error) {
            // Send api error related diagnostics
            sendErrorDiagnostics(`TCApi-error`, DEFAULT_DELAY, error, {
                method,
                options: jsonStringifyProperties(options),
                response: jsonStringifyProperties(response),
            });

            if (error === 'Request timed out.') {
                throw new ServerError(error);
            }  else if (error instanceof ServerError) {
                throw error;
            }

            console.error('[TCApi]: Fetch failed. Server could not be reached, probably offline.', {
                url, data, headers, error
            });
            
            throw new OfflineError(`${error}`);
        }
    }
}

export interface TCApiCallOptions<TData = Object>
{
    data?: TData;
    backend?: TCApiBackend;
    timeout?: number;
    authToken?: string;
    forceIncludeToken?: boolean;
}

export interface TCApiResponse
{
    [key: string]: any;
}