import { getProperty } from "../helper/object";



export default class Fetch
{
    public static fetch(input?: Request | string, init?: ExtendedRequestInit)
    {   
        let timeout = getProperty<number>(init, 'timeout', 60000);
        const request = window.fetch(input, init);

        if (timeout == null || timeout <= 0) {
            return request;
        } else {
            return Promise.race([
                request,
                new Promise<never>((resolve, reject) => window.setTimeout(() => reject(`Request timed out (${timeout / 1000} seconds).`), timeout))
            ]);
        }
    }
}

export interface ExtendedRequestInit extends RequestInit
{
    /** Request timeout. Default: 10000 */
    timeout?: number;
}