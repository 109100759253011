













import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {ToastMessageOptions, ToastMessageQueueItem, StringOrHtml, ToastMessageWrapper} from '../index';
import ToastMessage from './message.vue';

@Component({components: {'toast-message': ToastMessage}})
export default class Toasts extends Vue
{
    $refs: Vue['$refs'] & {
        toasts: ToastMessage[];
    };

    toastId = 0;
    messageQueue: ToastMessageQueueItem[] = [];

    mounted()
    {
        this.$root.$refs.toasts = this;
    }

    show = async (message: StringOrHtml, options?: ToastMessageOptions) => {
        let resolveUntilClosed: Function;
        let untilClosedPromise = new Promise<void>((resolve, reject) => {
            resolveUntilClosed = resolve
        });

        let data = <ToastMessageQueueItem>{
            text: message, 
            options: <ToastMessageOptions>options, 
            id: this.toastId++,
            untilClosedPromise,
            resolveUntilClosed: resolveUntilClosed,
        };
        
        this.messageQueue.push(data);

        await this.$nextTick();
        
        const result = new ToastMessageWrapper(
            this.$refs.toasts[this.$refs.toasts.length - 1],
            untilClosedPromise
        );

        return result;
    }

    closeToast(item: ToastMessageQueueItem) {
        const itemToRemove = this.messageQueue.find(o => o.id == item.id);
        if (itemToRemove == null) {
            return;
        }

        this.messageQueue.splice(this.messageQueue.indexOf(itemToRemove), 1);
        item.resolveUntilClosed();
    }
}

