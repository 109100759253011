var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "view" },
    [
      _c("top-header", { staticClass: "view-header has-text-centered" }, [
        _c("i", {
          staticClass: "fas fa-cloud-download-alt",
          attrs: { slot: "icon", "aria-hidden": "true" },
          slot: "icon"
        }),
        _vm._v(
          "\n        " +
            _vm._s(_vm.$ugettext("Synchronization queue")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section view-content" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c(
            "div",
            { staticClass: "column is-6-tablet is-4-desktop is-4-fullhd" },
            [
              _c("div", { staticClass: "notification is-danger" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.$ugettext(
                        "This tool is only intended to be used for debugging client issues."
                      )
                    ) +
                    "\n                "
                )
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table is-striped is-fullwidth" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$ugettext("Info")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$ugettext("Actions")))])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.messages, function(message) {
                    return _c("tr", { key: message.id }, [
                      _c("td", [
                        _c("div", [
                          _c("b", [_vm._v(_vm._s(_vm.$ugettext("Id")) + ":")]),
                          _vm._v(" " + _vm._s(message.id))
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$ugettext("Timestamp")) + ":")
                          ]),
                          _vm._v(" " + _vm._s(message.timestamp))
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$ugettext("Data length")) + ":")
                          ]),
                          _vm._v(" " + _vm._s(message.data_length))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "button is-danger",
                            class: { "is-loading": _vm.isLoading },
                            attrs: { type: "button", disabled: _vm.isLoading },
                            on: {
                              click: function($event) {
                                return _vm.deleteMessage(message)
                              }
                            }
                          },
                          [_vm._m(0, true)]
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-trash-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }