import { Location as CoreLocation } from "../../../core/database/models/general";
import { ExtBaseNameMixin, ServiceItem, QuantifiableModelMixin } from "./general";
import { Base } from "../../../core/database/models/base";

export class Location extends CoreLocation
{

}

export class LocationRegion extends ExtBaseNameMixin
{
    location_id: number;
    postal_code_min: number;
    postal_code_max: number;

    location?: Location;
}

export class LocationDefaultServiceItem extends Base implements QuantifiableModelMixin
{
    quantity: number;
    location_id: number;
    service_item_id: number;

    location?: Location;
    service_item?: ServiceItem;
}