import { ExtBase, Model, Base } from "./base";
import { Dexie } from "dexie";
import { Database } from "..";

export class Business extends ExtBase
{
    parent_id: number;
    name: string;
    address_id: number;
    addresses: any;
    phone_id: number;
    phones: any;
    email_id: number;
    emails: any;
    location_id: number;

    location?: Location;
    address?: Address;
}

export enum LocationType {
    ORGANIZATION = 'organization',
    COUNTRY = 'country',
    REGION = 'region'
}

export class Location extends ExtBase implements ITypedModel
{
    type: string;
    parent?: Location;
    parent_id: number;
    address?: Address;
    address_id: number;
    name: string;
    lft: number;
    rght: number;
    tree_id: number;
    level: number;
    location_type: LocationType;
    currency_unit: string;

    rule_materials_client_allowed_groups: string;

    toString()
    {
        return this.name;
    }

    public static get_family(obj: Location, query: Dexie.Collection<Location, number>) {
        return query.and(o => {
            const isAncestor = o.lft <= obj.lft && o.rght >= obj.rght && o.tree_id == obj.tree_id;
            const isDescendant = o.lft >= obj.lft && o.rght <= obj.rght && o.tree_id == obj.tree_id;

            return isAncestor || isDescendant;
        });
    }

    public static get_descendants(obj: Location, db: Database, include_self = true) {
        return db.Location.filter(o => {
            let isDescendant = false;
            if (include_self) {
                isDescendant = o.lft >= obj.lft && o.rght <= obj.rght && o.tree_id == obj.tree_id;;
            }  else {
                isDescendant = o.lft >  obj.lft && o.rght <  obj.rght && o.tree_id == obj.tree_id;
            }

            return isDescendant;
        });
    }
}

export interface ITypedModel
{
    type: string;
}

export class TypedModel extends Model implements ITypedModel
{
    type: string;
}

export class GeolocationMixin
{
    latitude: number;
    longitude: number;
}

export abstract class AddressLike extends ExtBase implements TypedModel, GeolocationMixin 
{
    type: string;
    latitude: number;
    longitude: number;

    title: string;
    street_address: string;
    street_address2: string;
    street_address3: string;
    street_address4: string;
    region: string;
    postal_code: string;
    city: string;
    country: string;

    public asText(line_breaks = false, line_break = '\n')
    {
        let text = this.street_address;

        if (this.street_address2)
        {
            text += line_breaks ? line_break : ' ';
            text += this.street_address2;
        }

        if (this.postal_code)
        {
            text += ' ' + this.postal_code;
        }

        if (this.region)
        {
            text += line_breaks ? line_break : ', ';
            text += this.region;
        }

        text += line_breaks ? line_break : ', ';
        text += this.city;

        if (this.country)
        {
            text += line_breaks ? line_break : ' ';
            text += this.country;
        }

        return text;
    }

    toString()
    {
        return this.asText();
    }
}

export class Address extends AddressLike
{

}

export class ClientImage extends Base
{
    file: string;
    width: number;
    height: number;
}

export class ContentType extends Model {
    app_label: string;
    model: string;   
}