var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "tab-page-wrapper", class: { "is-active": _vm.isVisible } },
    [
      _c("header", { ref: "header" }, [_vm._t("header")], 2),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "main",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isVisible,
                expression: "isVisible"
              }
            ],
            staticClass: "tab-page",
            class: ["is-direction-" + _vm.direction]
          },
          [_vm._t("default")],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }