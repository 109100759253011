var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "view" },
    [
      _c("top-header", { staticClass: "view-header has-text-centered" }, [
        _c("i", {
          staticClass: "fas fa-cloud-download-alt",
          attrs: { slot: "icon", "aria-hidden": "true" },
          slot: "icon"
        }),
        _vm._v("\n        " + _vm._s(_vm.$ugettext("Synchronize")) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section view-content" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c(
            "div",
            { staticClass: "column is-6-tablet is-4-desktop is-4-fullhd" },
            [
              _c(
                "div",
                {
                  staticClass: "buttons is-centered",
                  on: { click: _vm.startSync }
                },
                [
                  _c(
                    "button",
                    { staticClass: "button has-text-uppercase is-medium" },
                    [
                      _c("span", { staticClass: "icon" }, [
                        !_vm.$store.getters.isSynchronizing
                          ? _c("i", {
                              staticClass: "fas fa-sync",
                              attrs: { "aria-hidden": "true" }
                            })
                          : _c("i", {
                              staticClass: "fas fa-spinner fa-spin",
                              attrs: { "aria-hidden": "true" }
                            })
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$ugettext("Synchronize")) +
                            "\n                        "
                        )
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("h1", { staticClass: "title is-5 has-text-centered" }, [
                _vm._v(_vm._s(_vm.$ugettext("Advanced")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons is-centered" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-info",
                    attrs: { type: "button" },
                    on: { click: _vm.showQueue }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$ugettext("Show queue")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-warning",
                    attrs: { type: "button" },
                    on: { click: _vm.clearQueue }
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$ugettext("Clear queue")))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-danger",
                    attrs: { type: "button" },
                    on: { click: _vm.resetClient }
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$ugettext("Reset client")))])
                  ]
                )
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "far fa-list-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-times", attrs: { "aria-hidden": "true" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "far fa-trash-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }