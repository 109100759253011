var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button",
      attrs: { disabled: _vm.isLoading },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "span",
        { staticClass: "icon" },
        [
          _vm.isLoading
            ? _c("i", {
                ref: "loadingIcon",
                staticClass: "fa fa-spinner fa-spin",
                attrs: { "aria-hidden": "true" }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading ? _vm._t("icon") : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }