





















































































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import SynchronizerIcon from './sync-icon.vue';
import SyncQueueIcon from './sync-queue-icon.vue';
import MessagesIcon from './messages-icon.vue';
import { AttributeType } from '../../../core/database/models/attribute';

@Component({components: {'sync-icon': SynchronizerIcon, MessagesIcon, SyncQueueIcon}})
export default class Navbar extends Vue {

    // View permissions
    PERM_CAN_CHANGE_LOCATION = 'maintenance_general.can_change_location_in_client'
    
    // Data
    isMenuExpanded = false;
    isFullScreen = false;
    constance: any = null;

    async mounted()
    {   
        this.constance = await this.$app.db.Attribute.get(AttributeType.CONSTANCE_CONFIG);
        this.constance = this.constance != null ? this.constance.data : {};
    }

    // Method
    toggleCollapsedMenu()
    {
        this.isMenuExpanded = !this.isMenuExpanded;
        this.$emit('toggled');   
    }

    // Close the menu on route change if the menu is open
    @Watch('$route')
    onRoute(to: any, from: any)
    {
        if (this.isMenuExpanded)
        {
            this.isMenuExpanded = false;
            this.$emit('toggled'); 
        }
    }

    toggleFullScreen()
    {
        const enterMethod = document.documentElement.requestFullscreen || (document as any).documentElement.webkitRequestFullscreen;
        const exitMethod = document.exitFullscreen || (document as any).webkitExitFullscreen;

        if (!this.isFullScreen) 
        {
            enterMethod.call(document.documentElement);
            this.isFullScreen = true;
        }
        else if (this.isFullScreen) 
        {
            exitMethod.call(document);
            this.isFullScreen = false;
        }

        this.isMenuExpanded = false;
    }

    get canChangeLocation() {
        return this.$app.hasPermission(this.PERM_CAN_CHANGE_LOCATION) && this.$app.user.isAuthenticated;   
    }
}
