import { ExtBaseNameAndDescriptionMixin, SparePartAssignmentLike, TaskStepLike, ServiceItemAssignmentLike, SortOrderModelMixin, IUserMixin, ItemPriceMixin, Stock, StockItem, Item, ItemType, StepStatus } from "./general";
import { Customer, CustomerEquipment, CustomerEquipmentPlan, CustomerContract, CustomerAddress } from "./customer";
import { Base } from "../../../core/database/models/base";
import { User } from "../../../core/database/models/auth";
import * as moment from 'moment';
import { duration, DurationDisplay } from "../../../core/helper/time";
import { ClientImage } from "../../../core/database/models/general";
import { DocumentationFile } from "./documentation";
import AppPlugin from "../../../core/plugin/app";
import { uuid4 } from "../../../core/helper/uuid";
import { MaintenanceAccount } from "./auth";
import Vue from "vue";
import {Location} from '../../../core/database/models/general';

export interface InvoiceRelevantMixin {
    is_invoice_relevant: boolean;
}

export class Order extends ExtBaseNameAndDescriptionMixin
{
    state: OrderState;
    type_id: number;
    priority_id: number;
    customer_id: number;
    contract_id: number;
    customer_equipment_id: number;
    customer_reference: string;
    customer_contact_person: string;
    customer_contact_phone: string;
    plan_id: number;
    due: Date;
    started_at: Date;
    completed_at: Date;
    invoicing_comment: string;
    completion_text: string;
    latitude: number;
    longitude: number;
    created_by_id: number;
    invoiced_by_id: number;

    created_by?: User;
    invoiced_by?: User;
    files?: DocumentationFile[];
    plan?: CustomerEquipmentPlan;
    priority?: OrderPriority;
    type?: OrderType;
    assignments?: OrderAssignment[];
    signatureImages?: OrderSignatureImage[];
    steps?: OrderStep[];
    serviceItemAssignments?: OrderServiceItemAssignment[];
    sparePartAssignments?: OrderSparePartAssignment[];

    country_location_id: number;
    country_location?: Location;

    completion_code_id: number;
    rejection_code_id: number;
    debit_code_id: number;
    damage_code: DamageCode;
    delivery_address?: CustomerAddress;

    private _completion_code?: CompletionCode;
    private _rejection_code?: RejectionCode;
    private _debit_code?: DebitCode;
    get completion_code() { return this._completion_code; }
    get rejection_code() { return this._rejection_code; }
    get debit_code() { return this._debit_code; }
    set completion_code(value: CompletionCode | undefined)
    {
        this._completion_code = value;
        if (value != null)
        {
            this.completion_code_id = value.id;
        }
    }
    set rejection_code(value: RejectionCode | undefined)
    {
        this._rejection_code = value;
        if (value != null)
        {
            this.rejection_code_id = value.id;
        }
    }
    set debit_code(value: DebitCode | undefined)
    {
        this._debit_code = value;
        if (value != null)
        {
            this.debit_code_id = value.id;
        }
    }

    private _customer: Customer;
    get customer() { return this._customer; }
    set customer(value: Customer) 
    { 
        this._customer = value; 

        if (value != null)
        {
            this.customer_id = value.id; 
        }
    }

    private _customer_equipment: CustomerEquipment;
    get customer_equipment() { return this._customer_equipment; }
    set customer_equipment(value: CustomerEquipment) 
    { 
        this._customer_equipment = value; 
        if (value != null)
        {
            this.customer_equipment_id = value.id; 
        }
    }

    private _contract: CustomerContract;
    get contract() { return this._contract; }
    set contract(value: CustomerContract) 
    { 
        this._contract = value; 
        if (value != null)
        {
            this.contract_id = value.id; 
        }
    }

    get isLate()
    {
        return this.due < new Date();
    }

    get timeLeft()
    {
        return this.getTimeLeft();
    }

    getTimeLeft(unitAbbreviations = undefined) {
        return duration(this.due, null, DurationDisplay.DAYS_AND_HOURS, ' ', unitAbbreviations);
    }
    
    get canBeAssigned()
    {
        return [OrderState.NEW, OrderState.STARTED, OrderState.INCOMPLETE].indexOf(this.state) !== -1;
    }

    isAssignedTo(user: User)
    {
        return this.assignments != null && this.assignments.find(o => o.user_id == user.id) != null;
    }
    
    get isInCompletedState() {
        return [OrderState.COMPLETED, OrderState.INVOICED, OrderState.REJECTED].indexOf(this.state) != -1;
    }

    getGoogleMapUrl(address?: CustomerAddress)
    {
        let customer = this.customer as Customer;
        let addresses = customer.addresses as CustomerAddress[];

        if (addresses == null || addresses.length == 0) {
            return null;
        }
        else {
            if (address == null) {
                address = addresses[0];
            }
            return `https://www.google.com/maps/search/?api=1&query=${address.addressForGeocoding()}`;
        }
    }

    static getStateDisplay(state: OrderState, app: AppPlugin) {
        switch (state) {
            case OrderState.NEW:
                return app.i18n.pgettext('maintenance.order.Order.state', 'New');
            case OrderState.STARTED:
                return app.i18n.pgettext('maintenance.order.Order.state', 'Started');
            case OrderState.INCOMPLETE:
                return app.i18n.pgettext('maintenance.order.Order.state', 'Incomplete');
            case OrderState.REJECTED:
                return app.i18n.pgettext('maintenance.order.Order.state', 'Rejected');
            case OrderState.COMPLETED:
                return app.i18n.pgettext('maintenance.order.Order.state', 'Completed');
            case OrderState.INVOICED:
                return app.i18n.pgettext('maintenance.order.Order.state', 'Invoiced');
        }
    }

    get totalValue() {
        const separator = '.';

        const getItemsTotalPriceSum = function(assignments: (OrderServiceItemAssignment | OrderSparePartAssignment)[]) {
            let result = 0.0;
            for (const assignment of assignments) {
                const item = (assignment as OrderServiceItemAssignment).service_item || (assignment as OrderSparePartAssignment).spare_part;
                if (assignment.is_invoice_relevant) {
                    result += assignment.total_price;
                }
            }

            return result;
        }
        
        const result = ((
            getItemsTotalPriceSum(this.serviceItemAssignments || []) + 
            getItemsTotalPriceSum(this.sparePartAssignments || [])
        ) / 100).toFixed(2).replace(',', separator);

        const [primary, secondary] = result.split(separator);
        return {
            primary, secondary, separator, 
            unit: (this.country_location != null ? this.country_location.currency_unit : null) || 'eur',
        }
    }

    toString() {
        return `${this.id} - ${this.name}`;
    }
}

export enum DamageCode
{
    MISUSE = 'misuse',
    VANDALISM = 'vandalism'
}

export interface IOrderMixin
{
    order_id: number;
    order?: Order;
}

export class OrderMixin implements IOrderMixin
{    
    public order_id: number;

    protected _order: Order | undefined;
    public get order() { return this._order; }
    public set order(value: Order | undefined)
    {
        this._order = value;
        if (value != null)
        {
            this.order_id = value.id;
        }
    }
}

export enum OrderState
{
    NEW = 'new',
    STARTED = 'started',
    INCOMPLETE = 'incomplete',
    COMPLETED = 'completed',
    INVOICED = 'invoiced',
    REJECTED = 'rejected'
}

export class OrderType extends ExtBaseNameAndDescriptionMixin implements SortOrderModelMixin
{
    color: string;
    sort_order: number;
    is_for_invoicing: boolean;
    is_available_in_client: boolean;
    is_equipment_required_on_invoice: boolean;
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    static EXT_ID_ABSENCE = 'Z0';
}

export enum OrderPriorityGroup
{
    NORMAL = 'normal',
    HIGH = 'high'
}

export class OrderPriority extends ExtBaseNameAndDescriptionMixin implements SortOrderModelMixin
{
    sort_order: number;
    group: OrderPriorityGroup;
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    toString()
    {
        return this.name;
    }
}

class OrderItemStockMixin
{
    stock_id: number;
    stock?: Stock;
}

export class OrderSparePartAssignment extends SparePartAssignmentLike implements IOrderMixin, IUserMixin , ItemPriceMixin, OrderItemStockMixin, InvoiceRelevantMixin
{
    original_unit_price: number;
    total_price: number;
    price_unit: string;
    additional_info: string;
    public order_id: number;
    is_invoice_relevant: boolean;
    parent_service_item_assignment_id: number;
    parent_spare_part_assignment_id: number;

    parent_service_item_assignment: OrderServiceItemAssignment;
    parent_spare_part_assignment: OrderSparePartAssignment;

    // Helper property for UI when adding new items
    is_sub_item: boolean;
    // Helper property for UI when adding new items
    child_assignments: (OrderServiceItemAssignment | OrderSparePartAssignment)[];
    // Helper property for UI when loading existing items
    has_child_assignments: boolean;

    protected _order: Order | undefined;
    public get order() { return this._order; }
    public set order(value: Order | undefined)
    {
        this._order = value;
        if (value != null)
        {
            this.order_id = value.id;
        }
    }

    user_id?: number;
    
    protected _user?: User;
    public get user(): User | undefined { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        if (value != null)
        {
            this.user_id = value.id;
        }
    }

    stock_id: number;
    stock?: Stock;
    stock_items?: StockItem[];
    stocks?: Stock[];

    get totalPriceFormatted()
    {
        return this.total_price != null ? this.total_price / 100 : null;
    }

    get hasQuantity() {
        return (this.quantity as any) !== '' && this.quantity != null;
    }

    public static async createNewForOrder(order: Order, app: AppPlugin) {
        let item = new OrderSparePartAssignment();
        item.stocks = [];
        item.quantity = 1;
        item.order = order;
        item.is_saved = false;
        item.user = app.user;
        item.offline_message_uuid = uuid4();
        item.stock_id = null;
        await this.assignAvailableStocks(item, app);
        item.created_at = new Date(); // Used as the key in the list view.
        item.is_sub_item = false;
        item.parent_service_item_assignment = null;
        item.parent_spare_part_assignment = null;
        item.child_assignments = [];

        if (lastOrderItemAssignmentCreatedAt && item.created_at.getTime() == lastOrderItemAssignmentCreatedAt.getTime()) {
            item.created_at.setMilliseconds(item.created_at.getMilliseconds() + 1);
        }
        lastOrderItemAssignmentCreatedAt = item.created_at;
        return item;
    }
    
    private static async assignAvailableStocks(item: OrderSparePartAssignment, app: AppPlugin)
    {            
        let stocks: Stock[] = [];
        let maintenanceAccount = await app.db.MaintenanceAccount.where('user_id').equals(app.user.id).first();

        if (item.spare_part_id != null)
        {
            let stockItems = await app.db.StockItem
                .where('item_id').equals(item.spare_part_id)
                .and(o => o.type == ItemType.SPARE_PART)
                .toArray();
            
            item.stock_items = stockItems.filter(o => o.item_id == item.spare_part_id);

            // Get item specific stocks
            let itemStocks = await app.db.Stock.where('id').anyOf(item.stock_items.map(o => o.stock_id)).toArray();

            // If the current user has a stock
            if (maintenanceAccount.stock != null)
            {
                // If the account's stock is present in the item's stocks
                let accountStockPresent = itemStocks.find(o => o.id == maintenanceAccount.stock_id) != null;
                if (accountStockPresent)
                {
                    // Add the account stock at the top
                    stocks.push(maintenanceAccount.stock);
                    // Remove account stock from the item stocks to prevent a duplicate
                    itemStocks = itemStocks.filter(o => o.id != maintenanceAccount.stock_id);
                }
            }

            // Add the filtered and processed item stocks
            stocks.push(...itemStocks);
        }

        item.stocks = stocks;

        // If the item stock_id is not set and it's unsaved find default stock (user, 400 or VVA)
        if (item.stock_id == null) {
            let selected_stock = null;
            item.stocks.some(stock => {
                // User stock is the one we want
                if (stock.id == maintenanceAccount.stock_id) {
                    selected_stock = stock.id;
                    return true; 
                }
                // 400 is selected only if user stock is not present
                else if (stock.ext_id == '400') {
                    selected_stock = stock.id;
                }
                // VVA is selected only if 400 is not present
                else if (!selected_stock && stock.ext_id == 'VVA') {
                    selected_stock = stock.id;
                } 
                return false;
            });
            item.stock_id = selected_stock;
        }
    }
}

let lastOrderItemAssignmentCreatedAt: Date = null;
export class OrderServiceItemAssignment extends ServiceItemAssignmentLike implements IOrderMixin, IUserMixin, ItemPriceMixin, OrderItemStockMixin, InvoiceRelevantMixin
{   
    original_unit_price: number;
    total_price: number;
    price_unit: string;
    additional_info: string;
    public order_id: number;
    is_invoice_relevant: boolean;
    parent_service_item_assignment_id: number;
    parent_spare_part_assignment_id: number;

    parent_service_item_assignment: OrderServiceItemAssignment;
    parent_spare_part_assignment: OrderSparePartAssignment;

    // Helper property for UI when adding new items
    is_sub_item: boolean;
    // Helper property for UI when adding new items
    child_assignments: (OrderServiceItemAssignment | OrderSparePartAssignment)[];
    // Helper property for UI when loading existing items
    has_child_assignments: boolean;

    protected _order: Order | undefined;
    public get order() { return this._order; }
    public set order(value: Order | undefined)
    {
        this._order = value;
        if (value != null)
        {
            this.order_id = value.id;
        }
    }

    user_id?: number;
    
    protected _user?: User;
    public get user(): User | undefined { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        if (value != null)
        {
            this.user_id = value.id;
        }
    }

    stock_id: number;
    stock?: Stock;
    
    get totalPriceFormatted()
    {
        return this.total_price != null ? this.total_price / 100 : null;
    }

    get hasQuantity() {
        return (this.quantity as any) !== '' && this.quantity != null;
    }
    
    public static createNewForOrder(order: Order, app: AppPlugin) {
        const item = new OrderServiceItemAssignment();
        item.quantity = 1;
        item.user = app.user;
        item.order = order;
        item.is_saved = false;
        item.offline_message_uuid = uuid4();
        item.created_at = new Date(); // Used as the key in the list view.
        item.is_sub_item = false;
        item.parent_service_item_assignment = null;
        item.parent_spare_part_assignment = null;
        item.child_assignments = [];

        if (lastOrderItemAssignmentCreatedAt && item.created_at.getTime() == lastOrderItemAssignmentCreatedAt.getTime()) {
            item.created_at.setMilliseconds(item.created_at.getMilliseconds() + 1);
        }
        lastOrderItemAssignmentCreatedAt = item.created_at;
        return item;
    }
}

export class OrderStep extends TaskStepLike implements IOrderMixin, IUserMixin 
{
    public order_id: number;
    public group: string;
    public obligatory: boolean;
    choices: string;
    choice: string;

    protected _order: Order | undefined;
    public get order() { return this._order; }
    public set order(value: Order | undefined)
    {
        this._order = value;
        if (value != null)
        {
            this.order_id = value.id;
        }
    }

    user_id?: number;
    
    protected _user?: User;
    public get user(): User | undefined { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        if (value != null)
        {
            this.user_id = value.id;
        }
    }
}

export class OrderAssignment extends Base
{
    order_id: number;
    user_id: number;

    private _user?: User;
    public get user() { return this._user; }
    public set user(value: User | undefined) { this._user = value; if (value != null) { this.user_id = value.id; } }

    private _order?: Order;
    public get order() { return this._order; }
    public set order(value: Order | undefined) { this._order = value; this.order_id = value != null ? value.id : this.order_id; }
}

export enum OrderImageDestination
{
    Order = 'order',
    CustomerEquipment = 'customer_equipment'
}

export class OrderSignatureImage extends ClientImage implements IUserMixin
{
    order_id: number;
    user_id: number;
    print_name: string;
}

export class CompletionCodeGroup extends ExtBaseNameAndDescriptionMixin
{
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    toString()
    {
        return this.name;
    }
}

export class CompletionCode extends ExtBaseNameAndDescriptionMixin
{
    completion_code_group_id: number;
    requires_completion_text: boolean;
    
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    toString()
    {
        return this.name;
    }
}

export class RejectionCode extends ExtBaseNameAndDescriptionMixin implements SortOrderModelMixin
{
    sort_order: number;
    
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    toString()
    {
        return this.name;
    }
}
export class DebitCode extends ExtBaseNameAndDescriptionMixin
{
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}

    toString()
    {
        return this.name;
    }
}