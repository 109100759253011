var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "page-navigation" } }, [
    _vm.isPageNavigationVisible
      ? _c(
          "div",
          {
            ref: "pageNavigation",
            staticClass: "page-navigation field has-addons has-addons-centered"
          },
          [
            _c("div", { staticClass: "control" }, [
              _c(
                "button",
                {
                  staticClass: "button page-button",
                  class: { "is-loading": _vm.isLoading },
                  attrs: {
                    type: "button",
                    disabled: !_vm.canMoveToPreviousPage
                  },
                  on: { click: _vm.previousPage }
                },
                [_c("bulma-icon", { attrs: { name: "fas fa-chevron-left" } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "control" }, [
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPageIndex,
                        expression: "selectedPageIndex"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedPageIndex = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  _vm._l(_vm.pageCount, function(n, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        domProps: {
                          value: index,
                          selected: index == _vm.pageIndex
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(index + 1) +
                            "\n                    "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "control" }, [
              _c(
                "button",
                {
                  staticClass: "button page-button",
                  class: { "is-loading": _vm.isLoading },
                  attrs: { type: "button", disabled: !_vm.canMoveToNextPage },
                  on: { click: _vm.nextPage }
                },
                [_c("bulma-icon", { attrs: { name: "fas fa-chevron-right" } })],
                1
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }