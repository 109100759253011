import BasePlugin, { PluginOptions, VueConstructor } from "../base";
import AppPlugin from "../app";
import { AttributeType, LocalizationData } from "../../database/models/attribute";
import { TCApiResponse } from "../../api/trackingcloud";
import { StringFormatArgs, format } from "../../helper/string";
import { StoreActions } from "../../../app/config/store";

export default class i18nPlugin extends BasePlugin<i18nPluginOptions> 
{
    private app: AppPlugin;
    private localization: LocalizationData = {
        translations: {},
        languageCode: 'en'
    };

    private knownMissingTranslations: {[key: string]: boolean} = {};
    
    constructor(app: AppPlugin) {
        super();
        this.app = app;
    }

    // Usually used as a view key to trigger updating translations
    get activeAvailableLanguage() {
        return this.app.store.getters.activeAvailableLanguage;
    }
    
    reload() {
        // Default localization
        this.localization = {
            translations: {},
            languageCode: this.app.userData.language
        };
        
        return this.app.api.call<i18nTCApiResponse>(this.app.config.clientsync.endpoints.i18n, {
            data: {
                language_code: this.app.userData.language,
            },
            backend: 'public'
        }).then((result) =>
        {
            let localization: LocalizationData = {
                languageCode: result.language_code,
                translations: result.translations
            };

            this.localization = localization;

            return this.app.db.Attribute.put({
                type: AttributeType.LOCALIZATION,
                data: localization,
                createdAt: new Date()
            })
        }).then(() =>
        {
        }).catch(error =>
        {
            this.app.db.Attribute.get(AttributeType.LOCALIZATION).then((fallbackLocalization) =>
            {
                if (fallbackLocalization != null)
                {
                    this.localization = fallbackLocalization.data as LocalizationData;
                    console.log('using fallback localization.');
                }
            }, (error) =>
            {
                // Unnecessary error message (when it fails, client has just reset and thus doesn't have fallback localization nor access token)
                /*this.app.toasts.show(
                    this.ugettext('Failed to load localization! Falling back to english.'), {
                        duration: 5000
                    }
                );*/
            });
        }).then(() => {
            this.app.store.commit(StoreActions.SET_ACTIVE_AVAILABLE_LANGUAGE, this.localization.languageCode);
            (window as any).__language__ = this.localization.languageCode;
        })
    }

    protected configure(Vue: VueConstructor, options: i18nPluginOptions): PromiseLike<void> 
    {
        Vue.prototype.$gettext = this.gettext;
        Vue.prototype.$ugettext = this.ugettext;
        Vue.prototype.$pgettext = this.pgettext;
        Vue.prototype.$i18n = this;
        return this.reload();
    }

    // arrow function so that "this" context remains as i18nPlugin when injected to Vue prototype.
    /**  */
    public gettext = (message: string, args?: StringFormatArgs, autoEscape = true): string =>
    {
        return this.pgettext('', message, args);
    }

    public ugettext = (message: string, args?: StringFormatArgs, autoEscape = true) => this.gettext(message, args, autoEscape);

    // arrow function so that "this" context remains as i18nPlugin when injected to Vue prototype.
    /**  */
    public pgettext = (context: string, message: string, args?: StringFormatArgs, autoEscape = true): string =>
    {

        let key = `${context}:${message}`;

        if (key in this.localization.translations)
        {
            return format(this.localization.translations[key], args, autoEscape);
        }
        else
        {
            if (key in this.knownMissingTranslations == false)
            {
                this.knownMissingTranslations[key] = true;
                if (this.app.config.logging.i18n)
                {
                    console.warn(`Unknown translation: "${message}" (context: "${context}"), args: `, args);
                }
            }
            
            return format(message, args, autoEscape);
        }
    }
}

export interface i18nPluginOptions extends PluginOptions
{
    app: AppPlugin;
}

export interface i18nTCApiResponse extends TCApiResponse
{
    language_code: string;
    translations: {[key: string]: string};
}