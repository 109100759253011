import Vue, { VueConstructor as VueConstructorBase } from "vue";

// Type used as a shorthand for "typeof Vue".
export type VueConstructor = VueConstructorBase<Vue>;

export default abstract class BasePlugin<OptionsType extends PluginOptions>
{
    constructor()
    {

    }

    /** 
     * Method used to install the plugin. 
     * @see https://vuejs.org/v2/guide/plugins.html#Writing-a-Plugin for more information.
     * */
    protected abstract configure(Vue: VueConstructor, options?: OptionsType): PromiseLike<void>;

    public install(options?: OptionsType): PromiseLike<void>
    {
        return new Promise<void>((resolve, reject) =>
        {
            Vue.use((vue: VueConstructor, options?: OptionsType) =>
            {
                this.configure(vue, options).then(resolve, reject);
            }, options);
        })
    }
}

export interface PluginOptions
{
    [key: string]: any;
}