import { RouteConfig } from "vue-router";
import { TCApiResponse } from "../../api/trackingcloud";
import { UserType } from "../../database/models/attribute";

export enum RouteAuthentication
{
    /** Anonymous or authenticated users allowed. */
    Any,
    /** Only anonymous users are allowed. */
    OnlyAnonymous,
    /** Only authenticated users are allowed. Default. */
    OnlyAuthenticated
}

export type AuthRouteConfig = RouteConfig &
{
    meta?: {
        authentication?: {
            type: RouteAuthentication,
            permissions?: string[];
        }
    }
}

/** TC sign_in api response. */
export interface AuthenticationTCApiResponse extends TCApiResponse
{
    id: number;
    permissions: string[];
    language: string;
    token: string;
    user?: string;
    type: UserType;
}

/** Represents a collection of data used to authenticate user via Tracking Cloud backend. */
export interface AuthenticationBody
{
    username: string;
    password: string;
    checksum: number;
}

/** Represents the result returned by AppPlugin.authenticate. */
export interface AuthenticationResult
{
    errorMessage?: string;
    isSuccess: boolean;
}

export enum AuthenticationState
{
    ANONYMOUS,
    AUTHENTICATED,
    TOKEN_EXPIRED,
    OFFLINE,
}