import AppPlugin from "../../plugin/app";

/**
 * Represents an enum of all attribute types.
 */
export enum AttributeType
{
    LAST_SYNCHRONIZATION = 'last-synchronization',
    CURRENT_USER = 'current-user',
    LOCALIZATION = 'localization',
    CONFIG = 'config',
    CONSTANCE_CONFIG = 'constance-config'
}

export type UserType = 'super' | 'normal';

/** Generic base attribute. */
export default class Attribute<T = Object>
{
    type: AttributeType;
    data: T;
    createdAt: Date;
}

/** Attribute that describes when the last synchronization occurred. */
export class LastSynchronization extends Attribute<Date> { }

/** Represents information about the currently logged in user. */
export interface UserData
{
    id: number | null;
    language: string;
    token: string | null;
    permissions: string[];
    type: UserType;
    isAuthenticated: boolean;
    checksum: number;
}

/** Attribute that stores information about the currently logged in user. */
export class CurrentUser extends Attribute<UserData>
{

}

/** Attribute that stores localization messages. */
export class Localization extends Attribute<LocalizationData>
{

}

export class LocalizationData
{
    languageCode: string;
    translations: {[key: string]: string};
}

export class ConstanceConfig extends Attribute<any>
{
    private readonly app: AppPlugin;

    constructor(app: AppPlugin)  {
        super();
        this.app = app;
    }
    API_ITEMS_FI_ALLOWED_IN_CLIENT_GROUPS: string;

    get itemsFiAllowedInClientGroupExtIds() {
        return this.API_ITEMS_FI_ALLOWED_IN_CLIENT_GROUPS.split(',');
    }

    async getItemsAllowedInClientGroupIds() {
       return (await this.app.db.ItemGroup.filter(g => this.app.constance.itemsFiAllowedInClientGroupExtIds.map(e => e.toLowerCase()).indexOf(g.ext_id) != -1).toArray()).map(o => o.id);
    }
}
