(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("moment"), require("VueRouter"), require("Vuex"), require("Dexie"), require("SignaturePad"));
	else if(typeof define === 'function' && define.amd)
		define(["Vue", "moment", "VueRouter", "Vuex", "Dexie", "SignaturePad"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("Vue"), require("moment"), require("VueRouter"), require("Vuex"), require("Dexie"), require("SignaturePad")) : factory(root["Vue"], root["moment"], root["VueRouter"], root["Vuex"], root["Dexie"], root["SignaturePad"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__54__, __WEBPACK_EXTERNAL_MODULE__118__) {
return 