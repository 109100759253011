import { ExtBaseNameAndDescriptionMixin, SerialNumberMixin, Equipment, ExtBaseNameMixin, TaskLike, IUserMixin, NameAndDescriptionMixin } from "./general";
import { Location, AddressLike, ClientImage, TypedModel } from '../../../core/database/models/general';
import { OrderType, Order } from "./order";
import { User, UserAccount } from "../../../core/database/models/auth";
import { Base } from "../../../core/database/models/base";
import { ModelChangedTransaction, ModelChangedTransactionKind, Transaction } from "../../../core/database/models/tracking";
import { DocumentedModelMixin, DocumentationLink, DocumentationFile } from "./documentation";

export class CustomerEquipment extends ExtBaseNameAndDescriptionMixin implements SerialNumberMixin, DocumentedModelMixin
{
    serial_number: string;
    serial_number_fixed: string;

    name_fixed: string;

    delivery_customer_id: number;
    equipment_id: number;
    location_id: number;
    warranty_expires_at: Date;
    country_location_id: number;

    equipment?: Equipment;
    location?: Location;
    delivery_customer?: Customer;

    external_documentation_links?: DocumentationLink[];
    documentation_files?: DocumentationFile[];

    // Add more types with | when new transaction types are added
    transactions?: (CustomerEquipmentChangeLogTransaction | CustomerEquipmentOrderTransaction)[];

    get isWarrantyActive()
    {
        return this.warranty_expires_at != null && this.warranty_expires_at >= new Date()
    }

    toString()
    {
        if (this.serial_number != null)
        {
            return `${this.name} - ${this.serial_number}`;
        }
        
        return this.name;
    }
}

export enum CustomerSigningType
{
    NOT_REQUIRED = 'not-required',
    REQUIRED = 'required'
}

export class Customer extends ExtBaseNameAndDescriptionMixin implements DocumentedModelMixin
{
    group_id: number;
    location_id: number;
    signing_type: CustomerSigningType;

    location?: Location;
    group?: CustomerGroup;
    addresses?: CustomerAddress[];
    contacts?: CustomerContact[];
    
    external_documentation_links?: DocumentationLink[];
    documentation_files?: DocumentationFile[];

    confirmation_address_number: string;
    debtor_address_number: string;
    delivery_address_number: string;
    invoicing_address_number: string;

    billing_customer_id: number;
    billing_customer?: Customer;

    toString()
    {
        return `${this.name} - ${this.ext_id}`;
    }

    get delivery_address() {
        if (this.addresses) {
            const deliveryAddress = this.addresses.find(o => o.number == this.delivery_address_number);

            if (deliveryAddress != null) {
                return deliveryAddress;
            }

            const primaryAddress = this.addresses.find(o => o.type == CustomerAddressType.TYPE_PRIMARY);
            if (primaryAddress != null) {
                const address = Object.assign(new CustomerAddress(), primaryAddress);
                address.type = CustomerAddressType.TYPE_DELIVERY;
                address.id = null;
                return address;
            }
        }

        return null;
    }
}

export class CustomerGroup extends ExtBaseNameAndDescriptionMixin 
{

}

export class CustomerContract extends ExtBaseNameMixin implements DocumentedModelMixin
{
    customer_id: number;
    equipment_id: number;
    valid_from: Date;
    valid_to: Date;
    type_id: number;
    customer_reference: string;

    equipment?: CustomerEquipment;
    customer?: Customer;
    type?: CustomerContractType;
    
    external_documentation_links?: DocumentationLink[];
    documentation_files?: DocumentationFile[];

    toString()
    {
        return `${this.name} - ${this.ext_id}`;
    }
}

export class CustomerContractType extends ExtBaseNameAndDescriptionMixin
{

}

export class CustomerEquipmentPlan extends ExtBaseNameMixin
{
    priority_id: number;
    equipment_id: number;
    task_id: number;
    order_type_id: number;
    cycle_duration: number;
    cycle_starts_at: Date;
    call_horizon: number;
    location_id: number;

    location?: Location;
    order_type?: OrderType;
    task?: CustomerEquipmentPlanTask;
    equipment?: CustomerEquipment;
    priority?: ExtBaseNameAndDescriptionMixin;
}

export class CustomerEquipmentPlanPriority extends ExtBaseNameAndDescriptionMixin
{

}

export class CustomerEquipmentPlanTask extends TaskLike
{
    location_id: number;

    location?: Location;
}

export enum CustomerAddressType
{
    TYPE_PRIMARY = 'primary',
    TYPE_BILLING = 'billing',
    TYPE_DEBTOR = 'debtor',
    TYPE_CONFIRMATION = 'confirmation',
    TYPE_DELIVERY = 'delivery',
    TYPE_OTHER = 'other',
    TYPE_DISPATCH = 'dispatch',
    TYPE_UNKNOWN = 'unknown',
}

export class CustomerAddress extends AddressLike
{
    customer_id: number;
    customer?: Customer;

    number: string;

    full_text: string;
    full_text_lower: string;

    type: CustomerAddressType;

    preProcessInstance()
    {
        super.preProcessInstance();
        this.full_text = this.toString();
        this.full_text_lower = this.full_text.toLowerCase();
    }

    public addressForGeocoding() {
        let text = '';

        if (this.street_address2) {
            text += this.street_address2 + ' ';
        }        
        
        if (this.postal_code) {
            text += this.postal_code + ' ';
        }

        if (this.street_address4) {
            text += this.street_address4 + ' ';
        }  

        if (this.country) {
            text += this.country + ' ';
        }  

        return text.trim();
    }

}

export class CustomerEquipmentChangeLogTransaction extends ModelChangedTransaction
{
    customer_equipment_id: number;
    data: string;
    device_name: string;
    kind;

    customer_equipment?: CustomerEquipment;

    get isInitial()
    {
        return this.kind === ModelChangedTransactionKind.INITIAL;
    }

    get isUpdate()
    {
        return this.kind === ModelChangedTransactionKind.UPDATE;
    }
}

export class CustomerEquipmentOrderTransaction extends Transaction
{
    customer_equipment_id: number;
    order_id: number;

    legacy_history_import_description: string;
    order?: Order;
    customer_equipment?: CustomerEquipment;
}

export class CustomerContact extends Base implements NameAndDescriptionMixin, TypedModel
{
    name: string;
    description: string;
    type: string;

    customer_id: number;
    title: string;
    phone_number: string;
    email: string;
    
    customer?: Customer;

    toString()
    {
        return `${this.name}, ${this.phone_number}, ${this.email}`;
    }
}