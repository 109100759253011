import BasePlugin, { PluginOptions, VueConstructor } from "../base";
import { Database } from "../../database";
// AppDataBase is imported so that $app.db is of the right type
import AppDatabase from '../../../app/database';
import { TCApiResponse, TCApi } from "../../api/trackingcloud";
import { CurrentUser, AttributeType, UserData, UserType, ConstanceConfig } from "../../database/models/attribute";
import VueRouter, { RawLocation, Route, Location, RouteConfig } from "vue-router";
import { AppPluginOptions } from "./config";
import { AuthRouteConfig, RouteAuthentication, AuthenticationBody, AuthenticationResult, AuthenticationTCApiResponse, AuthenticationState } from "./authentication";
import Fetch from "../../api/fetch";
import Synchronizer from "./synchronizer";
import { StoreActions } from "../../../app/config/store";
import ToastsPlugin from "../toasts";
import i18nPlugin from "../i18n";
import Vue from "vue";
import { User } from "../../database/models/auth";
import { Diagnostics } from "../../diagnostics";
import InitialLoading from "../../initial-loading";
import { ServiceItem, SparePart } from "../../../app/database/models/general";
import { ServerError } from "../../api/errors";

export default class AppPlugin extends BasePlugin<AppPluginOptions> 
{
    private _$root: Vue;

    public get $root() { return this._$root; }
    public set $root(value: Vue)
    {
        this._$root = value;
        this._toasts.$root = this._$root;
    }

    // used to keep track of the route the user was trying to access before
    // being redirected to sign in route.
    private afterSignedInRoute: string;

    private _options: AppPluginOptions;
    /** AppPlugin options. */
    public get options() { return this._options; }

    // Access to toasts
    private _toasts: ToastsPlugin;
    public get toasts() { return this._toasts; }

    private _i18n: i18nPlugin;
    public get i18n() { return this._i18n; }

    // Access to router
    private _router: VueRouter;
    /** Application router. */
    private get router() { return this._router; }

    private _db: AppDatabase;
    /** Application database */
    public get db() { return this._db; }
    
    private _api: TCApi;
    /** Tracking Cloud API. */
    public get api() { return this._api; }

    private _synchronizer: Synchronizer;
    /** Synchronizer. */
    public get synchronizer() { return this._synchronizer; }

    private _config: AppConfig;
    /** Client configuration. */
    public get config() { return this._config; }
    
    private _store: AppPluginOptions['store'];
    /** Client global state. */
    public get store() { return this._store; }

    // These getters wrap properties found in the store for easy access.
    //#region store getter wrappers
    
    public get userData(): UserData { return this.store.getters.userData; }

    /** Currently active user. */
    public get user(): User { return this.store.getters.user; }

    public constance: ConstanceConfig;

    //#endregion

    /** Anonymous user (default) */
    public static get anonymousUserData(): UserData 
    {
        return {
            isAuthenticated: false,
            token: null,
            id: null,
            language: 'en',
            permissions: [],
            type: 'normal',
            checksum: -1
        }
    };

    public static readonly anonymousUser = new User();
    
    protected async configure(Vue: VueConstructor, options: AppPluginOptions): Promise<void>
    {
        Diagnostics.app = this;
        const serverConfigKey = '--client-server-config';

        // Register plugin
        Vue.prototype.$app = this;

        this._options = options;
        this._router = options.router;
        this._store = options.store;
        this._toasts = options.toasts;
        this._i18n = options.i18n;
        this._synchronizer = new Synchronizer(this);
        this._api = new TCApi(this);
        
        this.bindDOMEvents();

        // By default, the user is anonymous.
        this.store.commit(StoreActions.SET_USER_DATA, AppPlugin.anonymousUserData);
        this.store.commit(StoreActions.SET_USER, AppPlugin.anonymousUser);
        
        // By default, the route to navigate to after signing in is taken from the settings.
        // Later this route is set by the route guard if an anonymous user tries to access a protected route.
        this.afterSignedInRoute = this.router.resolve({name: this.options.authentication.routes.afterSignedIn}).href;
        
        // Load cached config as a fallback
        this.options.diagnostics.append('Loading application config...');
        InitialLoading.postProgressMessage('Loading configuration...', 0.1); // 10%
        return Fetch.fetch(`${window.location.protocol}//${window.location.host}/config.json`, { method: 'GET' })
        .then((response) =>
        {
            return response.json();
        }, (error) =>
        {
            return JSON.parse(localStorage.getItem(serverConfigKey));
        })
        .then(async (config) =>
        {
            localStorage.setItem(serverConfigKey, JSON.stringify(config));

            this._config = config;
            this._db = new (options.database as typeof AppDatabase)(this.config.clientsync.databaseName);
            // Some table names are namespaced to avoid conflicting names,
            // since all client database tables exist in the same database without app name prefixes.
            this.db.mapTableAliases();
            this.db.mapTableClasses();
            // Export the db to the window for performing debug queries
            (window as any).__db = this._db;
            
            await this.options.diagnostics.dumpSyncQueue();

            return this.db.Attribute.put({
                type: AttributeType.CONFIG,
                data: {
                    config
                },
                createdAt: new Date()
            });
        })
        .then(() =>
        {
            return this.checkDatabase();
        })
        .then(() =>
        {
            // Casted manually because for some reason VS Code does not properly resolve the return type of Dexie.get.
            return <any>this.db.Attribute.get(AttributeType.CURRENT_USER);
        })
        .then((user) =>
        {
            if (user != null)
            {
                let userData = user.data;

                this.store.commit(StoreActions.SET_USER_DATA, userData);
                return this.verifyRestoredAuthentication(userData);
            }
            else
            {
                return Promise.resolve(AuthenticationState.ANONYMOUS);
            }
        })
        .then((state) =>
        {
            switch(state)
            {
                case AuthenticationState.ANONYMOUS:
                    break;
                case AuthenticationState.AUTHENTICATED:
                    break;
                case AuthenticationState.TOKEN_EXPIRED:
                    return this.signOut();
                case AuthenticationState.OFFLINE:
                    this.router.push({name: this.options.authentication.routes.signIn});
                    break;
            }
        })
        .then(() =>
        {
            this.hookRouter();
        });
    }

    protected checkDatabase()
    {
        this.options.diagnostics.append('[database] Checking integrity...');
        InitialLoading.postProgressMessage('Verifying data...', 0.1); // 20%

        // Open a transaction with all tables
        // If the transaction fails, the DB is most likely broken and needs to be put out of its misery
        return this.db.transaction('rw', this.db.tables, () =>
        {
            let promises: PromiseLike<any>[] = [];

            for(let table of this.db.tables)
            {
                for(let index of table.schema.indexes)
                {
                    promises.push(
                        table.where(index.name).equals(1).first()
                    );
                }
            }

            return Promise.all(promises);
        }).catch((error) =>
        {
            console.error('DB check failed. Ashes to ashes.');
            console.error(error);
            indexedDB.deleteDatabase(this.config.clientsync.databaseName);
            location.reload();
        }).then(() => {
            this.options.diagnostics.append('[database] Check finished.');
        });
    }

    /** Makes sure protected routes cannot be accessed before being signed in. */
    protected hookRouter()
    {
        this.router.beforeEach((to: AuthRouteConfig, from, next) =>
        {
            // auth type defaults to OnlyAuthenticated
            let authType = to.meta != null && to.meta.authentication != null ? to.meta.authentication.type : RouteAuthentication.OnlyAuthenticated;
 
            // Anonymous and authentication required
            if (!this.user.isAuthenticated && authType == RouteAuthentication.OnlyAuthenticated)
            {
                // Next route cannot be sign in or out route
                if (to.name != this.options.authentication.routes.signIn && to.name != this.options.authentication.routes.signOut)
                {
                    this.afterSignedInRoute = to.path;
                }
                // Continue to the sign in route
                next({name: this.options.authentication.routes.signIn});
            }

            // Authenticated user and anonymous required
            else if((this.user.isAuthenticated && authType == RouteAuthentication.OnlyAnonymous))
            {
                // Continue to the "no permission" route
                next({name: this.options.authentication.routes.noPermission});
            }
            else
            {
                // Continue normally
                next();
            }
        });
    }

    private async updateConstance() {
        const constance = await this.synchronizer.updateConstance();
        this.constance = Object.assign(new ConstanceConfig(this), constance);
    }
    
    private verifyRestoredAuthentication(existingUserData: UserData): Promise<AuthenticationState>
    {
        InitialLoading.postProgressMessage('Authenticating...', 0.1); // 30%

        this.options.diagnostics.append('[authentication] Beginning to verify...');
        return new Promise<AuthenticationState>((resolve, reject) => {
            if (this.userData == null || (this.userData.token == null || this.userData.token.length == 0)) {
                resolve(AuthenticationState.ANONYMOUS);
                return;
            }

            this.options.diagnostics.append('[authentication] Authenticating...');
            // Call TCApi to verify the token
            return this.api.call('maintenance_validate_token', {backend: 'public', data: {token: this.userData.token}})
            .then((result: {success: boolean, user: AuthenticationTCApiResponse}) => {
                if (result != null && result.success) {
                    const languageChanged = this.userData.language != result.user.language;
                    // Update data
                    return this.updateUserData(result.user, this.userData.checksum)
                    .then(() => {

                        this.options.diagnostics.append('[authentication] Successfully authenticated.');

                        InitialLoading.postProgressMessage('Synchronizing...', 0.1); // 40%
    
                        this.options.diagnostics.append('[synchronizer] starting...');
                        
                        return this._i18n.reload()
                    })
                    .then(() => {
                        return this.updateConstance()
                    })
                    .then(() => {
                        resolve(AuthenticationState.AUTHENTICATED);
                    });

                } else {
                    this.options.diagnostics.append('[authentication] Failed to authenticate - token expired.');
                    resolve(AuthenticationState.TOKEN_EXPIRED);
                }
            }, async (error) =>
            {
                if (error instanceof ServerError) {
                    // Certain HTTP error codes are intentionally hidden or allow continuing normally
                    if (!this.isBlockingHttpStatusCode(error.status)) {
                        console.error('[authentication] Non-blocking HTTP error code, continue as authenticated.');
                        await this.storeUserData(existingUserData);
                        await this.synchronizer.updateUser();
                        return resolve(AuthenticationState.AUTHENTICATED);
                    }
                }

                await this.options.diagnostics.error('[authentication] Failed to authenticate - error.', error);
                console.error('[Authentication] Failed to verify authentication token. Likely offline.');
                console.error(error);
                resolve(AuthenticationState.OFFLINE);
            });
        }).then((result) => {
            
            return this.synchronizer.pull(true, {postProgress: true}).then(() =>
            {
                this.options.diagnostics.append('[synchronizer] initial pull completed.');
            }).then(() => {
                this.options.diagnostics.append('[authentication] Verification completed.');
                return result;
            });
        });
    }

    /**
     * Whether or not the HTTP status code blocks sync.
     * @param code
     */
    public isBlockingHttpStatusCode(code: number) {
        // 502: Gateway timeout (allowed; Django is down)
        // 503: Service unavailable
        return [200, 502, 503].indexOf(code) === -1;
    }

    /** Primary method of authenticating a user. */
    public async authenticate(data: AuthenticationBody): Promise<AuthenticationResult> {   
        // Get currently authenticated user, if any

        let existingAuth = await this.db.Attribute
            .where('type').equals(AttributeType.CURRENT_USER)
            .and(o => (o as CurrentUser).data.checksum === data.checksum)
            .first() as CurrentUser;
        
        let tcAuthResponse: AuthenticationTCApiResponse;
        try 
        {
            tcAuthResponse = await this.api.call<AuthenticationTCApiResponse>(this.config.clientsync.endpoints.login, {
                backend: 'public',
                data: { username: data.username, password: data.password }
            });
            
            if (tcAuthResponse === null) {
                return { isSuccess: false, errorMessage: this.i18n.ugettext('Username or password is invalid.') }
            }
            if (tcAuthResponse.success === false)
            {
                return { isSuccess: false, errorMessage: tcAuthResponse.message }
            }
        }
        catch
        {
            if (existingAuth == null)
            {
                return { isSuccess: false, errorMessage: this.i18n.ugettext('Could not sign in. Please try again.') };
            }
            else
            {
                console.log('using fallback auth.');
                tcAuthResponse = existingAuth.data;
            }
        }
        
        // If the TC response or the current user is null (TC rejected or username & password don't match the previous checksum)
        if (tcAuthResponse == null)
        {
            return {isSuccess: false, errorMessage: this.i18n.ugettext('Username or password is invalid.')};
        }
            
        const languageChanged = tcAuthResponse.language != this.userData.language;
        await this.updateUserData(tcAuthResponse, data.checksum);
        
        // pull sets user
        if (languageChanged) {
            await this._i18n.reload();
        }

        this.$root.$toasts.show(this.$root.$pgettext('maintenance.client', 'Successfully signed in!'), {
            icon: 'fas fa-check has-text-success',
            duration: 5000,
        });

        this.$root.$toasts.show(
            this.$root.$pgettext('maintenance.client', 'Loading client data...'), {
            icon: 'fas fa-spinner fa-spin',
            duration: 5000,
        });

        await this.synchronizer.pull(true, {updateConstance: true});
        this.router.push(this.afterSignedInRoute);

        return { isSuccess: true };
    }

    private updateUserData(tcAuthResponse: AuthenticationTCApiResponse, checksum: number) {
        let userData = {
            id: tcAuthResponse.id,
            language: tcAuthResponse.language,
            permissions: tcAuthResponse.permissions,
            token: tcAuthResponse.token,
            type: tcAuthResponse.type,
            isAuthenticated: true,
            checksum: checksum
        } as UserData;
        
        return this.storeUserData(userData);
    }

    private storeUserData(userData: UserData) {
        this.store.commit(StoreActions.SET_USER_DATA, userData);

        return this.db.Attribute.put(<CurrentUser>{
            type: AttributeType.CURRENT_USER,
            data: userData,
            createdAt: new Date()
        });
    }

    public signOut(options?: {resetData: boolean}) {   
        return this.db.clearUserLocationAndUserLanguageBasedTablesAndForceInitialSync(this)
        .then(() => {
            return this.db.Attribute.delete(AttributeType.CURRENT_USER)
        })
        .then(() => {
            // Sign in as anonymous user
            this.store.commit(StoreActions.SET_USER_DATA, AppPlugin.anonymousUserData);
            this.store.commit(StoreActions.SET_USER, AppPlugin.anonymousUser);

            this.toasts.show('Successfully signed out!', {
                icon: 'fas fa-check has-text-success',
                duration: 5000,
                alignment: 'right'
            });
            
            if (options != null && options.resetData === true) {
                indexedDB.deleteDatabase(this.config.clientsync.databaseName);
            }
            
            const logoutUrl = `${this.config.urls.trackingcloud}/logout/?next=${this.config.urls.client}`
            location.href = logoutUrl;
        });
    }

    /**
     * Problem: soft keyboard doesn't resize page in fullscreen mode (it overlays it)
     * Attempt to detect it: 
     * WIP version of soft keyboard detection. Based on focusin and focusout events.
     * Doesn't solve figuring out the height of soft keybaord.
     * 
     * Figure out a way of detecting a device has a physical keyboard. Media query/user agent/something else?
     */
    private bindDOMEvents()
    {
        const isFormControl = (target: EventTarget) =>
        {
            if (target instanceof HTMLInputElement)
            {
                return true;
            }
            else if (target instanceof HTMLTextAreaElement)
            {
                return true;
            }
            else if (target instanceof HTMLElement && target.isContentEditable)
            {
                return true;
            }

            return false;
        }
        document.addEventListener('focusin', (e) =>
        {
            let target = e.target as HTMLElement;
            if (isFormControl(target))
            {
                let options = {
                    behavior: 'smooth',
                    block: 'center'
                } as ScrollIntoViewOptions;

                window.setTimeout(() =>
                {
                    target.scrollIntoView(options);

                    window.setTimeout(() =>
                    {
                        target.scrollIntoView(options);
                    }, 250);
                }, 250);
            }
        });

        document.addEventListener('focusout', (e) =>
        {

        });
    }

    /**
     * Returns boolean value if current user has permission given in permission argument.
     * Default user is currently logged user but user can be given in arguments to override current user.
     * Checks also user superuser status.
     * @param permission permission name 
     * @param user default value null
     * @returns boolean value if user has given permission
     */
    public hasPermission(permission:string, user:User = null) {
        user = (user !== null) ? user : this.user;
        return user.is_superuser || user.permissions.indexOf(permission) > -1;
    }

    /**
     * Returns a list of ItemGroup ids which the user is allowed to use.
     * Based on Location.rule_material_client_allowed_groups.
     */
    public async getAllowedItemGroupIds() {
        const maintenanceAccount = await this.db.MaintenanceAccount.where('user_id').equals(this.user.id).first();
        const currentUserCountry = maintenanceAccount.country_id ? await this.db.Location.get(maintenanceAccount.country_id) : null;
        const allowedItemGroupExtIdsInClient = currentUserCountry && currentUserCountry.rule_materials_client_allowed_groups ? currentUserCountry.rule_materials_client_allowed_groups.toLowerCase().split(',') : [];
        
        if (currentUserCountry == null) {
            this.toasts.error(this.i18n.pgettext(
                'maintenance.auth',
                'No country set for {user}! Cannot resolve allowed item groups.',
            {user: this.user.displayName}))
        }

        if (allowedItemGroupExtIdsInClient.length > 0) {
            return (await this.db.ItemGroup.filter(g => allowedItemGroupExtIdsInClient.indexOf(g.ext_id.toLowerCase()) != -1).toArray()).map(o => o.id);
        } else {
            return [];
        }
    }

    /**
     * Helper that populates an array of items with quantity unit and stocks.
     * Filters out inactive stocks if excludeInactiveStocks is set to true (default).
     */
    public async postProcessAndFilterItems(items: ServiceItem[] | SparePart[], excludeInactiveStocks = true, fetchSubItems = true) {
        const itemIds = items.map(o => o.id);
        const [stockItems, stocks, quantityUnits, subItemAssignments] = await Promise.all([
            this.db.StockItem.where('item_id').anyOf(itemIds).toArray(),
            this.db.Stock.toArray(),
            this.db.ItemQuantityUnit.toArray(), 
            fetchSubItems ? this.db.SubItemAssignment.where('parent_id').anyOf(itemIds).toArray() : [],
        ]);
        
        let subItemChildItems: ServiceItem[] | SparePart[] = [];

        if (fetchSubItems) {
            // Recursively populate sub items
            subItemChildItems = await this.postProcessAndFilterItems(
                await this.db.Item.where('id').anyOf(subItemAssignments.map(o => o.child_id)).toArray(),
                true,
                false
            );
        }

        for (const item of items) {
            item.quantity_unit = quantityUnits.find(o => o.id == item.quantity_unit_id);
            item.stock_items = stockItems.filter(o => o.item_id == item.id);
            item.sub_item_assignments = subItemAssignments.filter(o => o.parent_id == item.id);
        }

        for (const stockItem of stockItems) {
            stockItem.stock = stocks.find(o => o.id == stockItem.stock_id);
        }

        for (const subItemAssignment of subItemAssignments) {
            subItemAssignment.parent = items.find(o => o.id == subItemAssignment.parent_id);
            subItemAssignment.child = subItemChildItems.find(o => o.id == subItemAssignment.child_id);
        }

        const result: ServiceItem[] | SparePart[] = [];

        for (const item of items) {
            if (
                excludeInactiveStocks == false || 
                // If the service item has an active stock, include it in the result
                (item.stock_items.find(o => o.stock.active == true) != null)
            ) {
                result.push(item);
            }
        }

        return result;
    }
}

/** Represents the configuration the node backend returns. */
export interface AppConfig
{
    urls: {
        trackingcloud: string;
        client: string;
    }

    clientsync: {
        databaseName: string;
        interval: number;
        endpoints: {
            pull: string;
            pull_one: string;
            push: string;
            i18n: string;
            login: string;
        }
    }

    logging: {
        clientsync: boolean;
        i18n: boolean;
    }
}