var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "is-borderless message-icon-wrapper",
      attrs: { disabled: !_vm.$app.user.isAuthenticated }
    },
    [
      _c("transition-group", { attrs: { name: "counter" } }, [
        _vm.$app.user.isAuthenticated &&
        _vm.$store.getters.unseenMessages.normal > 0
          ? _c("span", { key: "left", staticClass: "count is-left" }, [
              _vm._v(_vm._s(_vm.$store.getters.unseenMessages.normal))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$app.user.isAuthenticated &&
        _vm.$store.getters.unseenMessages.high > 0
          ? _c("span", { key: "right", staticClass: "count is-right" }, [
              _vm._v(_vm._s(_vm.$store.getters.unseenMessages.high))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", {}, [_c("i", { staticClass: "fas fa-bell" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }