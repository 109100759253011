var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isVisible
      ? _c("section", { staticClass: "background-fade" }, [
          _c("main", { staticClass: "spinner-wrapper" }, [
            _c("div", { staticClass: "spinner fa-spin" }),
            _vm._v(" "),
            _c("div", { staticClass: "icon-foreground" }, [
              _c("i", {
                staticClass: "fas fa-cloud",
                attrs: { "aria-hidden": "true" }
              })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }