var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "tab-container view" }, [
    _c(
      "header",
      {
        ref: "header",
        staticClass: "view-header",
        class: { "is-disabled": _vm.isDisabled === true }
      },
      _vm._l(_vm.pages, function(page, index) {
        return _c(
          "div",
          {
            key: index,
            ref: "tabHeader",
            refInFor: true,
            class: [
              { "is-active": index == _vm.activePageIndex },
              "tab-header",
              "is-unselectable"
            ],
            on: {
              click: function($event) {
                return _vm.tabClicked(index)
              }
            }
          },
          [
            _c("div", {
              domProps: { innerHTML: _vm._s(page.$refs.header.innerHTML) }
            }),
            _vm._v(" "),
            _c("transition", { attrs: { name: "border-fade" } }, [
              index == _vm.activePageIndex
                ? _c("div", { staticClass: "border" })
                : _vm._e()
            ])
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("section", { staticClass: "pages view-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }