










































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class PageNavigation extends Vue {        
    
    @Prop({type: Boolean})
    isLoading: boolean;

    @Prop({type: Number})
    pageCount: boolean;

    @Prop({type: Boolean})
    isPageNavigationVisible: boolean;

    @Prop({type: Boolean})
    canMoveToPreviousPage: boolean;

    @Prop({type: Boolean})
    canMoveToNextPage: boolean;

    @Prop({type: Number})
    pageIndex: number;

    selectedPageIndex: number;

    data() {
        return {
            selectedPageIndex: this.pageIndex,
        }
    }

    @Watch('pageIndex')
    updateSelectedPageIndex() {
        this.selectedPageIndex = this.pageIndex;
    }

    previousPage() {
        this.$emit('previousPage');
    }

    nextPage() {
        this.$emit('nextPage');
    }  

    @Watch('selectedPageIndex')
    jumpToPage(index: number) {
        this.$emit('jumpToPage', index);
    }
}
