import { repeat } from "./string";

export const zeroPrefix = (number: string | number, threshold = 10) =>
{
    if (typeof number === 'string')
    {
        number = parseFloat(number);
    }

    if (number < threshold)
    {
        // Length of the number without possible negative sign
        const numberLength = Math.abs(number).toString().length;
        // Number length = floor(log(10, n)) Where n is the number
        const zeroPrefixLength = Math.floor(Math.log10(threshold)) + 1;
        // Since the number is less than the prefix, the number of zeroes to add is equal to
        // to the length of the number subtracted from the max number of zeroes
        const zeroPrefixLengthToAdd = zeroPrefixLength - numberLength;

        number = `${repeat('0', zeroPrefixLengthToAdd)}${number}`;
    }

    return number.toString();
}

/**
 * @see https://stackoverflow.com/a/14428340
 * @param decimalPlaces 
 * @param groupLength 
 * @param groupDelimiter 
 * @param decimalDelimiter 
 */
export const format = (number: number, decimalPlaces = 2, groupLength = 3, groupDelimiter = ',', decimalDelimiter = '.') =>
{
    const pattern = '\\d(?=(\\d{' + (groupLength) + '})+' + (decimalPlaces > 0 ? '\\D' : '$') + ')';
    let formattedNumber = number.toFixed(Math.max(0, ~~decimalPlaces));

    return (decimalDelimiter ? formattedNumber.replace('.', decimalDelimiter) : formattedNumber).replace(new RegExp(pattern, 'g'), '$&' + (groupDelimiter));
};