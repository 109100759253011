var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content != null
    ? _c("span", [
        typeof _vm.content == "string"
          ? _c("span", { domProps: { textContent: _vm._s(_vm.content) } })
          : _c("span", { domProps: { innerHTML: _vm._s(_vm.content.html) } })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }