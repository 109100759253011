




























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import BaseValueComponent from '../../../core/vue/base/value.vue';
import KeyCode from '../../../core/enum/keycode';
import { Route, NavigationGuard} from 'vue-router/types/router';

@Component
export default class ModalCard extends Vue {
    mounted() {
        window.addEventListener('keyup', (e) =>
        {
            if (e.keyCode == KeyCode.Escape)
            {
                this.tryClose();
            }
        }, {once: true});
    }

    beforeRouteLeave() {
        this.close();
    }

    tryClose() {   
        this.close();
    }

    close() {
        this.$emit('close');
    }

    submit() {
        this.$emit('submit');
    }

    @Prop({type: Object, default: () => { return {}; }})
    value: ModalData;
}

export interface ModalData {
    isEnabled: boolean;
}
