import * as moment from 'moment';
import {zeroPrefix} from './number';

export enum DurationDisplay {
    TIME,
    DATE_TIME,
    DAYS_AND_HOURS,
}

export const unitAbbreviationsDefault =  {'year': 'y', 'month': 'm', 'day': 'd'};

export function duration(from: Date, to: Date | null = null, display = DurationDisplay.DATE_TIME, separator = ':',
unitAbbreviations = unitAbbreviationsDefault) {
    if (to == null) {
        to = new Date();
    }

    const _from = moment(from);
    const _to = moment(to);

    const duration = moment.duration(_from.diff(to));

    // Negative
    let prefix = '';
    if (duration.asMilliseconds() < 0) {
        prefix = '-';
    }

    let yearsUnit = `<span class="years">${unitAbbreviations.year}</span>`;
    let monthsUnit = `<span class="months">${unitAbbreviations.month}</span>`;
    let daysUnit = `<span class="seconds">${unitAbbreviations.day}</span>`;
    let hourUnit = `<span class="hours">h</span>`;
    let minuteUnit = `<span class="minutes">m</span>`;
    let secondsUnit = `<span class="seconds">s</span>`;

    let years = Math.abs(duration.years());
    let months = Math.abs(duration.months());
    let days = Math.abs(duration.days());
    let hours = Math.abs(duration.hours());
    let minutes = Math.abs(duration.minutes());
    let seconds = Math.abs(duration.seconds());

    // If display 
    if (display === DurationDisplay.TIME) {
        hours = days > 0 ? (days * 24) + months  + hours : hours;
        days = 0;
    }

    let formattedDays = days.toString();
    let formattedHours = zeroPrefix(hours, 10);
    let formattedMinutes = zeroPrefix(minutes, 10);
    let formattedSeconds = zeroPrefix(seconds, 10);

    let parts: string[] = [];

    if (years > 0) {
        parts.push(`${years}${yearsUnit}`);
    }
    // If there are years, show months or 0 months for clarity
    if (months > 0 || years > 0) {
        parts.push(`${months}${monthsUnit}`);
    }
    // If there are months, show days or 0 days for clarity
    if (days > 0 || months > 0) {
        parts.push(`${days}${daysUnit}`);
    }
    
    const hoursPart = `${formattedHours}${hourUnit}`;
    const minutesPart = `${formattedMinutes}${minuteUnit}`;
    const secondsPart = `${formattedSeconds}${secondsUnit}`;
    
    if (display === DurationDisplay.DAYS_AND_HOURS) {
        parts.push(hoursPart); 
    } else {
        parts.push(hoursPart, minutesPart, secondsPart);
    }

    return `${prefix}${parts.join(separator)}`;
}


export const toDatetimeLocal = (date: Date) =>
{
                
    const ten = function (i) {
        return (i < 10 ? '0' : '') + i;
    };

    let YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds())
    ;
    return YYYY + '-' + MM + '-' + DD + 'T' +
            HH + ':' + II + ':' + SS;
};