import Vuex from 'vuex';
import { CurrentUser, UserData } from '../../core/database/models/attribute';

export enum StoreActions
{
    SET_SYNCHRONIZATION_STATE = 'setSynchronizationState',
    IS_SYNCHRONIZER_OVERLAY_VISIBLE = 'setIsSynchronizerOverlayVisible',
    SET_LAST_SYNCHRONIZATION_ID = 'setLastSynchronizationId',
    SET_USER_DATA = 'setUserData',
    SET_USER = 'setUser',
    SET_UNSEEN_MESSAGE_COUNTS = 'setMessageCounts',
    SET_SYNCHRONIZATION_QUEUE_SIZE = 'setSynchronizationQueueSize',
    SET_ACTIVE_AVAILABLE_LANGUAGE = 'setActiveAvailableLanguage',
}

const store = new Vuex.Store({
    state: {
        isSynchronizing: false,
        isSynchronizerOverlayVisible: true,
        user: null,
        synchronizationId: '',
        userData: null,
        unseenMessages: {
            high: 0,
            normal: 0,
        },
        synchronizationQueueSize: 0,
        activeAvailableLanguage: 'en',
    },
    mutations: {
        [StoreActions.SET_USER]: (state, user) => state.user = user,
        [StoreActions.SET_SYNCHRONIZATION_STATE]: (state, isActive: boolean) => state.isSynchronizing = isActive,
        [StoreActions.IS_SYNCHRONIZER_OVERLAY_VISIBLE]: (state, enabled: boolean) => state.isSynchronizerOverlayVisible = enabled,
        [StoreActions.SET_LAST_SYNCHRONIZATION_ID]: (state, id: string) => state.synchronizationId = id,
        [StoreActions.SET_USER_DATA]: (state, data) => state.userData = data,
        [StoreActions.SET_UNSEEN_MESSAGE_COUNTS]: (state, data) => state.unseenMessages = data,
        [StoreActions.SET_SYNCHRONIZATION_QUEUE_SIZE]: (state, size) => state.synchronizationQueueSize = size,
        [StoreActions.SET_ACTIVE_AVAILABLE_LANGUAGE]: (state, language) => state.activeAvailableLanguage = language,
    },
    getters: {
        isSynchronizing: state => state.isSynchronizing,
        isSynchronizerOverlayVisible: state => state.isSynchronizerOverlayVisible,
        user: state => state.user,
        userData: state => state.userData,
        synchronizationId: state => state.synchronizationId,
        unseenMessages: state => state.unseenMessages,
        synchronizationQueueSize: state => state.synchronizationQueueSize,
        activeAvailableLanguage: state => state.activeAvailableLanguage,
    },
    actions: {

    }
});

export default store;