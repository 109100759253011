






































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {format} from '../../../core/helper/number';

@Component({
    
})
export default class CurrentMonthInvoicing extends Vue
{
    isLoaded = false;
    didFail = false;

    data: {
        realised_invoicing: number;
        target_invoicing: number;
        currency: string;
    }

    asFormatted(value: number)
    {
        // Divide by 100 to get display value (1/100 currency to single currency units, e.g. from cents to euros)
        return format(value, 0, 3, '');
    }
    mounted()
    {
        this.$app.api.call('client_get_invoicing_data', {
            data: {
                args: {
                    user_pk: this.$app.user.id
                }
            }
        }).then((data) =>
        {
            this.data = data as CurrentMonthInvoicing['data'];
            this.isLoaded = true;
        }, (error) =>
        {
            this.isLoaded = true;
            this.didFail = true;
        });
    }
}   
