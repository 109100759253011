var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["field", _vm.fieldClassNames] },
    [
      _vm.isInlineLabel && _vm.labelText != null
        ? _c(
            "label",
            {
              class: [_vm.sizeClassNames, _vm.labelClassNames],
              attrs: { for: _vm.fieldId }
            },
            [_vm._v(_vm._s(_vm.labelText))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("groupedLeft"),
      _vm._v(" "),
      _vm._t("addonsLeft"),
      _vm._v(" "),
      _c("div", { staticClass: "field", style: [_vm.wrapperFieldStyles] }, [
        _c("div", { staticClass: "control", class: [_vm.controlClassNames] }, [
          _c("input", {
            ref: "inputElement",
            class: [
              _vm.inputClassName,
              _vm.sizeClassNames,
              _vm.inputClassNames
            ],
            attrs: {
              slot: "control",
              id: _vm.fieldId,
              type: _vm.inputType,
              placeholder: _vm.placeholderText,
              disabled: !_vm.isEnabled
            },
            domProps: { value: _vm.value },
            on: { input: _vm.updateInputValue },
            slot: "control"
          }),
          _vm._v(" "),
          !_vm.isInlineLabel && _vm.labelText != null
            ? _c(
                "label",
                {
                  class: [_vm.sizeClassNames, _vm.labelClassNames],
                  attrs: { for: _vm.fieldId }
                },
                [_vm._v(_vm._s(_vm.labelText))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasIconLeft
            ? _c(
                "span",
                { staticClass: "icon is-left", class: [_vm.sizeClassNames] },
                [_vm._t("iconLeft")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasIconRight
            ? _c(
                "span",
                { staticClass: "icon is-right", class: [_vm.sizeClassNames] },
                [_vm._t("iconRight")],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        this.$slots.error != null ||
        this.$slots.help != null ||
        this.helpText != null
          ? _c("p", { staticClass: "help" }, [
              _c(
                "span",
                { staticClass: "has-text-danger" },
                [_vm._t("error")],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "has-text-grey", class: [_vm.helpClassNames] },
                [
                  _vm._t("help", function() {
                    return [_vm._v(_vm._s(_vm.helpText))]
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("addonsRight"),
      _vm._v(" "),
      _vm._t("groupedRight")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }