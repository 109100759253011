




import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import { AttributeType, CurrentUser, UserData } from '../../../core/database/models/attribute';
import { StoreActions } from '../../config/store';

@Component({})
export default class AuthenticationComplete extends Vue {

    mounted() {
        this.completeLogin();
    }

    async completeLogin() {
        const token = this.$route.query['token'] as string;

        // Read token from URL
        const options = {
            authToken: token
        }
        const userData: UserData = await this.$app.api.call('get_user_data', options);

        this.$store.commit(StoreActions.SET_USER_DATA, userData);

        await this.$app.db.Attribute.put(<CurrentUser>{
            type: AttributeType.CURRENT_USER,
            data: userData,
            createdAt: new Date()
        });

        location.href = '/';
    }
}
