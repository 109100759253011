
























import {Component, Prop} from 'vue-property-decorator';
import BaseValueComponent from '../../../vue/base/value.vue';
import ToastIcon from './icon.vue';
import ToastContent from './content.vue';
import {ToastMessageOptions, ToastAlignment, StringOrHtml, ToastMessageLike} from '../index';
import Vue from 'vue';

const messageSpacingPixels = 6;

@Component({components: {ToastIcon, ToastContent}})
export default class ToastMessage extends BaseValueComponent<ToastMessageLike>
{
    // Typings
    $refs: Vue['$refs'] & {
        wrapper: HTMLElement;
        message: HTMLElement;
    };

    value: ToastMessageLike;

    // Properties
    wrapperStyles = {'padding': `${messageSpacingPixels / 2}px 0`};
    shouldNotifyCannotBeClosedByClicking = false;
    
    autoCloseTimeout?: number | null;

    // Computed properties
    get valueDefaults(): ToastMessageLike
    {
        return {
            text: '',
            options: <ToastMessageOptions> {
                duration: null,
                alignment: 'middle'
            }
        }
    }

    get wrapperClassNames() { return { 
        'has-icon': this.value.options.icon != null,
        'has-action': this.value.options.action != null,
        'is-right': this.value.options.alignment == 'right', 
        'is-shake': this.shouldNotifyCannotBeClosedByClicking
    }}

    // Life-cycle methods
    mounted()
    {
        if (this.value.options.duration != null && this.value.options.duration > 0)
        {
            this.autoCloseTimeout = window.setTimeout(() => this.close(), this.value.options.duration);
        }
    }

    // Utility methods
    onFadeEnter(el: HTMLElement)
    {
        let message = this.$refs.message;
        this.$set(this.wrapperStyles, 'maxHeight', `${message.offsetHeight + messageSpacingPixels}px`);
    }

    close()
    {
        if (this.autoCloseTimeout != null)
        {
            window.clearTimeout(this.autoCloseTimeout);
            this.autoCloseTimeout = null;
        }

        this.$emit('close', this.value);
    }

    onClick(e: MouseEvent)
    {
        e.stopImmediatePropagation();
        let shouldNotify: boolean | void = false;

        if (this.value.options.onClick != null)
        {
            shouldNotify = this.value.options.onClick(this.close);
        }

        if (this.value.options.closeOnClick !== false)
        {
            this.close();
        }
        else if(shouldNotify !== false)
        {
            this.notifyCannotBeClosed();
        }
    }

    onActionClick(e: MouseEvent)
    {
        e.stopImmediatePropagation();
        let shouldNotify: boolean | void = false;

        if (this.value.options.action != null)
        {
            if (this.value.options.action.onClick != null)
            {
                shouldNotify = this.value.options.action.onClick(this.close);
            }

            if (this.value.options.action.closeOnClick !== false)
            {
                this.close();
            }
            else if(shouldNotify !== false)
            {
                this.notifyCannotBeClosed();
            }
        } 
    }

    notifyCannotBeClosed()
    {
        this.shouldNotifyCannotBeClosedByClicking = true;
        setTimeout(() => this.shouldNotifyCannotBeClosedByClicking = false, 500);
    }
}
