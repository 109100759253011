import { Base } from "./base";
import { UserAccount } from "./auth";

export class Transaction extends Base
{
    device_name: string;
    action_time: Date;
    user_account_id: number;
    
    user_account?: UserAccount;
}

export enum ModelChangedTransactionKind
{
    INITIAL = 'initial', 
    UPDATE = 'update'
}


export class ModelChangedTransaction extends Transaction
{
    kind: ModelChangedTransactionKind;
    data: string;
    request_id: string;
}