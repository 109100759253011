var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-card" } }, [
    _c("div", { staticClass: "modal is-active" }, [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function($event) {
            return _vm.tryClose()
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-card" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              }
            }
          },
          [
            _vm._t("header", function() {
              return [
                _c("header", { staticClass: "modal-card-head" }, [
                  _c(
                    "p",
                    { staticClass: "modal-card-title" },
                    [_vm._t("title")],
                    2
                  ),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "button is-delete",
                    attrs: { type: "button", "aria-label": "close" },
                    on: {
                      click: function($event) {
                        return _vm.tryClose()
                      }
                    }
                  })
                ])
              ]
            }),
            _vm._v(" "),
            _c("main", { staticClass: "modal-card-body" }, [_vm._t("body")], 2),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "modal-card-foot" },
              [
                _vm._t("footer", function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { disabled: !_vm.value.isEnabled }
                      },
                      [_vm._v("Ok")]
                    )
                  ]
                })
              ],
              2
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }