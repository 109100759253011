


















import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component
export default class TopHeader extends Vue {        
    // TODO: Fade out on scroll down, fade back in on scroll up
}
