



























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class ServiceWorkerNotifier extends Vue
{
    isUpdateAvailable = false;

    mounted()
    {
        document.addEventListener('serviceWorkerInstalled', e => this.onServiceWorkerInstalled(e as CustomEvent));
        document.addEventListener('serviceWorkerCachingFinished', e => this.onServiceWorkerCachingFinished(e as CustomEvent));
    }

    install()
    {
        location.reload();
    }

    private onServiceWorkerInstalled(e: CustomEvent) {
        this.isUpdateAvailable = true;
    };

    private onServiceWorkerCachingFinished(e: CustomEvent) {
        // this.$app.toasts.show('Caching finished! You may go offline. But only for a moment.');
    };
}   
