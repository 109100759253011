var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "view" },
    [
      _c("top-header", { staticClass: "view-header has-text-centered" }, [
        _c("i", {
          staticClass: "fas fa-sign-in-alt",
          attrs: { slot: "icon", "aria-hidden": "true" },
          slot: "icon"
        }),
        _vm._v("\n        Sign out - Authentication\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "section view-content" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c(
            "div",
            { staticClass: "column is-6-tablet is-4-desktop is-4-fullhd" },
            [
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "buttons is-centered" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "button is-danger is-medium has-text-uppercase",
                    class: { "is-loading": _vm.isLoading },
                    attrs: { disabled: _vm.isLoading },
                    on: { click: _vm.confirmSignOut }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$ugettext("Sign out")))])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("h1", { staticClass: "title is-5" }, [
                _vm._v(_vm._s(_vm.$ugettext("Advanced")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.resetData,
                        expression: "resetData"
                      }
                    ],
                    staticClass: "is-checkradio",
                    attrs: { id: "restDataCheckbox", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.resetData)
                        ? _vm._i(_vm.resetData, null) > -1
                        : _vm.resetData
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.resetData,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.resetData = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.resetData = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.resetData = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "restDataCheckbox" } }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.$ugettext("Reset device data")) +
                        "\n                        "
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fas fa-sign-out-alt",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }