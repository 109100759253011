import Dexie from 'dexie';
import Attribute from './models/attribute';
import { SyncMessage, SyncCommand } from './models/clientsync';
import { User, UserAccount, Group } from './models/auth';
import { Address, Location, Business, ContentType } from './models/general';
import { OfflineModelInstance } from './models/client';

export abstract class Database extends Dexie
{
    public OfflineModelInstance: Dexie.Table<OfflineModelInstance, number>;
    public Attribute: Dexie.Table<Attribute, string>;
    public SyncMessage: Dexie.Table<SyncMessage, number>;
    public SyncCommand: Dexie.Table<SyncCommand, number>;
    public User: Dexie.Table<User, number>;
    public Group: Dexie.Table<Group, number>;
    public UserAccount: Dexie.Table<UserAccount, number>;
    public Location: Dexie.Table<Location, number>;
    public Address: Dexie.Table<Address, number>;
    public Business: Dexie.Table<Business, number>;
    public ContentType: Dexie.Table<ContentType, number>;

    public get tableAliases(): {[key: string]: Dexie.Table<any, any>}
    {
        return {}
    }

    constructor(name: string)
    {
        super(name);

        this.version(1).stores({
            OfflineModelInstance: '++id,table,key',
            Attribute: 'type',
            SyncMessage: '++id,uuid,timestamp',
            SyncCommand: 'id',
            User: 'id',
            Group: 'id',
            UserAccount: 'id, location_id, business_id, user_id',
            Address: 'id',
            Location: 'id, address_id',
            Business: 'id,address_id,location_id,parent_id',
            ContentType: 'id',
        });
    }

    public mapTableAliases()
    {
        // Function that creates a closure for mapping an alias
        function mapTableAlias(aliasName: string, actual: Dexie.Table<any, any>)
        {
            Object.defineProperty(database, aliasName, {
                get: () => actual
            });
        }

        let aliases = this.tableAliases;
        let database = this;

        for(let aliasName in aliases)
        {
            mapTableAlias(aliasName, aliases[aliasName]);
        }
    }

    public mapTableClasses()
    {
        this.OfflineModelInstance.mapToClass(OfflineModelInstance);
        this.Attribute.mapToClass(Attribute);
        this.SyncMessage.mapToClass(SyncMessage);
        this.SyncCommand.mapToClass(SyncCommand);
        this.User.mapToClass(User);
        this.Group.mapToClass(Group);
        this.UserAccount.mapToClass(UserAccount);
        this.Location.mapToClass(Location);
        this.Address.mapToClass(Address);
        this.Business.mapToClass(Business);
        this.ContentType.mapToClass(ContentType);
    }
}