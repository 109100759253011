var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "view" },
    [
      _c("top-header", { staticClass: "view-header" }, [
        _c("i", {
          staticClass: "fas fa-th-large",
          attrs: { slot: "icon", "aria-hidden": "true" },
          slot: "icon"
        }),
        _vm._v("\n        " + _vm._s(_vm.$ugettext("Main menu")) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "view-content" },
        [
          _vm.canViewMonthTotalInvoicing
            ? _c("current-month-invoicing")
            : _vm._e(),
          _vm._v(" "),
          _c(
            "menu-block-container",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "order:list" } } },
                [
                  _c("menu-block", [
                    _c("i", {
                      staticClass: "fas fa-qrcode",
                      attrs: { slot: "icon", "aria-hidden": "true" },
                      slot: "icon"
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$ugettext("Orders")) +
                        "\n                "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "order:nearBy" } } },
                [
                  _c("menu-block", [
                    _c("i", {
                      staticClass: "fas fa-map-marker-alt",
                      attrs: { slot: "icon", "aria-hidden": "true" },
                      slot: "icon"
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$ugettext("Near by orders")) +
                        "\n                "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "customerEquipment:search" } } },
                [
                  _c("menu-block", [
                    _c("i", {
                      staticClass: "fas fa-cubes",
                      attrs: { slot: "icon", "aria-hidden": "true" },
                      slot: "icon"
                    }),
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$ugettext("Customer equipment")) +
                        "\n                "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }