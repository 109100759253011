














import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import BulmaFormField from './base.vue';

// https://stackoverflow.com/a/46181
const emailValidationRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

@Component({components: {BulmaFormField}, extends: BulmaFormField})
export default class BulmaEmailField extends BulmaFormField 
{       
    $refs: BulmaFormField['$refs'] & {
        inputField: BulmaFormField
    }

    updateInputValue()
    {
        let value = this.$refs.inputField.$refs.inputField.value;

        this.$emit('input', this.value,  value);
        this.isValid = emailValidationRegex.test(value);
    }
    
    focus(): void
    {
        this.$refs.inputField.focus();    
    }

    validate()
    {
        this.updateInputValue();
    }
}
