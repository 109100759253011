







import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {StringOrHtml} from '../index';

@Component
export default class ToastContent extends Vue
{
    @Prop()
    content: string | StringOrHtml;
}
