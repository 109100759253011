import { TCApi } from "../api/trackingcloud";
import { jsonStringifyProperties } from "../helper/object";
import AppPlugin from "../plugin/app";

export class Diagnostics {
    static app: AppPlugin;
    static get api() {
        return this.app.api;
    };

    private items: DiagnosticsMessage[] = [];

    public clear() {
        this.items = [];
    }

    public log(message: string, extra?: Object) {
        this.append(message, extra).logToConsole();
    }

    public append(message: string, extra?: Object) {
        const item = new DiagnosticsMessage(message, extra);
        this.items.push(item);
        return item;
    }

    public add(message: string, timestamp: Date,  extra?: Object) {
        const item = new DiagnosticsMessage(message, timestamp, extra);
        this.items.push(item);
        return item;
    }

    public async error(message: string, error: any, extra?: Object) {
        if (typeof error === 'string' || error == null) {
            error = new Error(error);
        }

        const stackTrace = await StackTrace.fromError(error);
        const stackTraceLines = stackTrace.map(o => o.toString());
        
        message = `${message}\n\tStack trace:\n${stackTraceLines.map(o => `\t${o}`).join('\n')}`;
        this.append(message, {
            ...extra,
            error: `${error}`,
            href: location.href,
            stacktrace: stackTraceLines.join('\n'),
        });
    }

    public async dumpSyncQueue() {
        const messages = await Diagnostics.app.db.SyncMessage.toArray();
        this.append(`clientsync-queue: ${messages.length} message(s)`, {
            messages: messages.map(m => m.asLoggableObject())
        })
    }

    public async flush(type?: string, delay = 0) {
        //@ts-ignore
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {effectiveType: 'unknown - what browser is this?'};
        
        this.append('Flushing diagnostics', {
            connection: jsonStringifyProperties(connection),
            config: Diagnostics.app.config
        });

        const items = [...this.items];
        const data = {
            type: type,
            message: items.map(o => o.formattedMessage).join('\n'),
            data: JSON.stringify(items, null, 4),
        };
        
        const trySendMessage = async () => {
            try {
                const response = await Diagnostics.api.call('push_client_diagnostics_message', {
                    data,
                    backend: 'public',
                    forceIncludeToken: true,
                });

                this.clear();
            } catch (error) {
                delay += 60000;
                this.error(`Failed to flush app messages. Trying again in a bit (${delay}ms).`, error);

                setTimeout(trySendMessage, delay);
            }
        };

        setTimeout(trySendMessage, delay);
    }

    static async reportPerformance(type: string, duration: number) {
        try {
            await Diagnostics.api.call('push_client_performance_metric', {
                data: {
                    type: type, 
                    duration: duration, 
                    user_id: Diagnostics.app.user.id, 
                    timestamp: new Date(), 
                },
                backend: 'public',
                forceIncludeToken: true,
            });
        } catch {
            // fails silently
        }
    }
}

export class DiagnosticsMessage {
    readonly timestamp: string;
    readonly message: string;
    readonly extra?: Object;

    get formattedMessage() {
        return `[${this.timestamp}] ${this.message}`;
    }

    constructor(message: string, extra?: Object)
    constructor(message: string, timestamp: Date, extra?: Object)
    constructor(message: string, extraOrTimestamp?: Date | Object, maybeExtra?: Object) {
        let timestamp: Date;
        let extra: Object;

        if (extraOrTimestamp instanceof Date) {
            timestamp = extraOrTimestamp;
            extra = maybeExtra;
        } else {
            timestamp = new Date();
            extra = extraOrTimestamp;
        }

        this.timestamp = timestamp.toISOString().replace('T', ' ').replace('Z', '');
        this.message = message;
        this.extra = extra;
    }

    public logToConsole() {
        console.log(this.formattedMessage, this.extra);
    }
}

export const DEFAULT_DELAY = 60000;

export async function sendErrorDiagnostics(name: string, delay = DEFAULT_DELAY, error, extra?: Object) {
    const errorDiagnostics = new Diagnostics();
    errorDiagnostics.append(`[ERROR] uncaught ${name}`);
    await errorDiagnostics.error(`${error}`, error, extra);
    errorDiagnostics.flush(`error:${name}`, delay);
}
