











































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import PageNavigation from './navigation.vue';

@Component({components: {PageNavigation}})
export default class SimplePagination extends Vue {        
    $refs: Vue['$refs'] & {
        itemsContainer: HTMLElement;
        pageNavigation: PageNavigation;
    }

    @Prop({type: Function, default: (item: any, index: number) => index})
    itemKeyGetter: (item: any, index: number) => string;

    @Prop({type: Array, default: () => []})
    items: any[];

    @Prop({type: Number, default: 50})
    perPage: number;

    @Prop({type: Number, default: 0})
    initialPageIndex: number;

    @Prop({type: String})
    itemContainerClassName: string;

    @Prop({type: Boolean, default: false})
    forceShowPageNavigation: boolean;

    @Prop({type: Boolean, default: true})
    autoShowPageNavigation: boolean;

    @Prop({type: Boolean, default: true})
    scrollToTopOnPageChange: boolean;

    get pageNumber() {
        return this.pageIndex + 1;
    }

    get pageCount() {
        return Math.ceil(this.items.length * 1.0 / this.perPage);
    }

    get canMoveToPreviousPage() {
        return !this.isLoading && this.pageIndex > 0 && this.pageCount > 0;
    }

    get canMoveToNextPage() {
        return !this.isLoading && this.pageIndex < this.pageCount - 1;
    }

    itemContainerStyles = {};
    pageIndex: number = 0;
    pageItemsStartIndex: number = 0;
    pageItemsEndIndex: number = 0;
    pageItems: any[] = [];
    statusMessage: string = '';
    shouldAutoShowPageNavigation = false;
    isPageNavigationVisible = false;
    isLoading = false;
    
    data() {
        return {
            pageIndex: this.initialPageIndex
        }
    }

    @Watch('items')
    itemsChanged() {
        this.capPageIndex();
    }

    @Watch('perPage')
    perPageChanged() {
        this.capPageIndex();
    }

    @Watch('pageIndex') 
    pageIndexChanged() {
        this.update();
        if (this.scrollToTopOnPageChange) {
            this.$refs.itemsContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    mounted() {
        this.update();
        this.updateAutoPageNavigationVisibility();
        this.updatePageNavigationVisibility();
    }

    capPageIndex() {
        if (this.pageIndex > this.pageCount - 1) {
            this.pageIndex = this.pageCount - 1;
        } else {
            this.update();
        }
    }

    update() {
        const start = Math.max(0, Math.min(this.pageIndex * this.perPage));
        const end = Math.min(start + this.perPage, this.items.length);

        this.pageItemsStartIndex = start;
        this.pageItemsEndIndex = end;
        this.pageItems = this.items.slice(start, end);
        this.statusMessage = this.$pgettext(
            'pagination',
            '{start_index} - {end_index} of {total_count}',
            {
                // Display: 1 .. n if n > 0. Used to show 0 - 0 instead of 1 - 0.
                start_index: end > 0 ? start + 1 : 0,
                end_index: end,
                total_count: this.items.length
            }
        )

        this.$emit('paginated', {start, end, pageNumber: this.pageNumber, pageIndex: this.pageIndex, status: this.statusMessage});
    }

    async moveToPreviousPage() {
        this.isLoading = true;
        this.pageIndex -= 1;
        await this.$nextTick();
        this.isLoading = false;
    }

    async moveToNextPage() {
        this.isLoading = true;
        this.pageIndex += 1;
        await this.$nextTick();
        this.isLoading = false;
    }

    jumpToPage(index: number) {
        this.pageIndex = index;
    }

    onListScroll(e: Event) {
        this.updateAutoPageNavigationVisibility();
    }

    updateAutoPageNavigationVisibility() {
        if (!this.autoShowPageNavigation) {
            return;
        }

        const threshold = window.innerHeight / 2;

        const element = this.$refs.itemsContainer;
        if (element.scrollTop < threshold) {
            this.shouldAutoShowPageNavigation = true;
        } else if (element.scrollTop + threshold >= element.scrollHeight - element.offsetHeight) {
            this.shouldAutoShowPageNavigation = true;
        } else {
            this.shouldAutoShowPageNavigation = false;
        }
    }

    @Watch('shouldAutoShowPageNavigation')
    @Watch('forceShowPageNavigation')
    @Watch('pageCount')
    async updatePageNavigationVisibility() {
        this.isPageNavigationVisible = this.pageCount > 1 && (this.forceShowPageNavigation || this.shouldAutoShowPageNavigation);
        await this.$nextTick();
        this.itemContainerStyles = {
            paddingTop: `${(this.isPageNavigationVisible ? (this.$refs.pageNavigation.$el as HTMLElement).offsetHeight : 0) + 16}px`
        }

        this.$emit('pageNavigationVisibilityChanged', this.isPageNavigationVisible);
    }
}
