import { Model } from "./base";

export class User extends Model
{
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff = false;
    is_active = false;
    is_superuser = false;
    date_joined: string;
    account?: UserAccount;
    permissions: string[] = [];
    groups: number[];

    get isAuthenticated()
    {
        return this.id != null;
    }

    get displayName()
    {
        return this.first_name.length == 0 ? this.username : `${this.first_name} ${this.last_name}`;
    }

    toString()
    {
        return this.displayName;
    }
}

export class Group extends Model
{
    name: string;
    permissions: string[];
}

export class UserAccount extends Model
{
    timezone: string;
    initial_password: boolean;
    business_id?: number;
    location_id?: number;
    user_id: number;
    language: string;

    user?: User; 
}