import BasePlugin, { VueConstructor, PluginOptions } from "../../../core/plugin/base";
import ToastsComponent from './vue/toasts.vue';
import Vue from "vue";
import ToastMessage from './vue/message.vue';

export default class ToastsPlugin extends BasePlugin<PluginOptions> 
{
    public $root: Vue;

    protected configure(Vue: VueConstructor): PromiseLike<void> 
    {
        // Register toasts as a global component.
        Vue.component('toasts', ToastsComponent);

        // Inject $toasts method via created so that we can gain access to the component
        // when $toasts.show is called.
        let plugin = this;

        Vue.mixin({
            created()
            {
                (this as any).$toasts = {
                    show: function() {
                        return plugin.show.apply(plugin, arguments);
                    },
                    error: function() {
                        return plugin.error.apply(plugin, arguments);
                    }
                }
            }
        });
        return Promise.resolve();
    }

    async show(message: StringOrHtml, options?: ToastMessageOptions): Promise<ToastMessageWrapper>
    {
        if (this.$root == null)
        {
            console.warn('Vue app is not initialized, unable to display toast. Falling back to alert.');
            alert(message);

            return <any>Promise.resolve();
        }
        const result = (this.$root.$refs.toasts as any).show(message, options);
        return result;
    }

    async error(message: StringOrHtml, options?: ToastMessageOptions): Promise<ToastMessageWrapper>
    {
        options = options != null ? options : {};
        options.icon = 'fas fa-exclamation-triangle has-text-danger';

        const result = (this.$root.$refs.toasts as any).show(message, options);
        return result;
    }

    async warning(message: StringOrHtml, options?: ToastMessageOptions): Promise<ToastMessageWrapper>
    {
        options = options != null ? options : {};
        options.icon = 'fas fa-exclamation-triangle has-text-warning';

        const result = (this.$root.$refs.toasts as any).show(message, options);
        return result;
    }
}

export type ToastAlignment = 'middle' | 'right';

export type StringOrHtml = string | { html: string };

export interface ToastMessageOptions
{
    duration?: number | null;
    alignment?: ToastAlignment;
    icon?: StringOrHtml;
    onClick?: (close: () => void) => boolean | void;
    closeOnClick?: boolean;
    action?: {
        text?: StringOrHtml;
        icon?: StringOrHtml;
        onClick?: (close: () => void) => boolean | void;
        closeOnClick?: boolean;
    }
}

export interface ToastMessageLike
{
    text: StringOrHtml;
    options: ToastMessageOptions;
}

export interface ToastMessageQueueItem extends ToastMessageLike
{
    id: number;
    untilClosedPromise: Promise<void>;
    resolveUntilClosed: Function;
}

export class ToastMessageWrapper
{
    private _toast: ToastMessage;
    private _untilClosed: Promise<void>;

    constructor(toast: ToastMessage, untilClosed: Promise<void>)
    {
        this._toast = toast;
        this._untilClosed = untilClosed;
    }

    close() {
        (this._toast as any).close();
    }

    untilClosed(): Promise<void>
    {
        return this._untilClosed;
    }
}

/** Plugin options */
export interface ToastsOptions extends PluginOptions
{
    
}