var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isUpdateAvailable
      ? _c("main", { staticClass: "update-background" }, [
          _c("div", { staticClass: "update-message" }, [
            _c("div", { staticClass: "header" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$ugettext("Update available")) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "buttons is-centered" }, [
              _c(
                "button",
                {
                  staticClass: "button is-large",
                  on: {
                    click: function($event) {
                      return _vm.install()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", {
                      staticClass: "fas fa-download",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$ugettext("Install")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-warning is-large",
                  on: {
                    click: function($event) {
                      _vm.isUpdateAvailable = false
                    }
                  }
                },
                [
                  _c("span", { staticClass: "icon" }, [
                    _c("i", {
                      staticClass: "fas fa-times",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.$ugettext("Not now")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "install-later-tip" }, [
                _c("span", { staticClass: "icon" }, [
                  _c("i", {
                    staticClass: "fas fa-info",
                    attrs: { "aria-hidden": "true" }
                  })
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$ugettext(
                        "You can install the update later by reloading the application."
                      )
                    )
                  )
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }