import { Base } from "../../../core/database/models/base";
import { OrderPriority, Order } from "./order";
import { User } from "../../../core/database/models/auth";
import { Location } from "../../../core/database/models/general";

export enum TechnicianMessageState
{
    NEW = 'new',
    SENT = 'sent'
}

export class TechnicianMessage extends Base
{
    priority_id: number;
    created_by_id: number;
    visibility_starts_at: Date;
    visibility_ends_at: Date;
    content: string;
    location_id: number;
    state: TechnicianMessageState;
    recipients?: TechnicianMessageRecipient[];

    private _priority?: OrderPriority;
    public get priority() { return this._priority; }
    public set priority(value: OrderPriority | undefined)
    {
        this._priority = value;
        this.priority_id = (value != null ? value.id : undefined) as number;
    }

    private _created_by?: User;
    public get created_by() { return this._created_by; }
    public set created_by(value: User | undefined)
    {
        this._created_by = value;
        this.created_by_id = (value != null ? value.id : undefined) as number;
    }
    
    private _location?: Location;
    public get location() { return this._location; }
    public set location(value: Location | undefined)
    {
        this._location = value;
        this.location_id = (value != null ? value.id : undefined) as number;
    }
}

export class TechnicianMessageRecipient extends Base
{
    message_id: number;
    user_id: number;

    private _message?: TechnicianMessage;
    public get message() { return this._message; }
    public set message(value: TechnicianMessage | undefined)
    {
        this._message = value;
        this.message_id = (value != null ? value.id : undefined) as number;
    }

    private _user?: User;
    public get user() { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        this.user_id = (value != null ? value.id : undefined) as number;
    }
}
export class TechnicianMessageConfirmation extends Base
{
    message_id: number;
    user_id: number;
    read_at: Date;

    private _message?: TechnicianMessage;
    public get message() { return this._message; }
    public set message(value: TechnicianMessage | undefined)
    {
        this._message = value;
        this.message_id = (value != null ? value.id : undefined) as number;
    }

    private _user?: User;
    public get user() { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        this.user_id = (value != null ? value.id : undefined) as number;
    }
}