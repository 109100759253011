var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" }, on: { enter: _vm.onFadeEnter } },
    [
      _c(
        "section",
        {
          ref: "wrapper",
          staticClass: "toast-message-wrapper",
          class: [_vm.wrapperClassNames],
          style: [_vm.wrapperStyles],
          on: { click: _vm.onClick }
        },
        [
          _c(
            "span",
            { ref: "message", staticClass: "toast-message" },
            [
              _vm.value.options.icon != null
                ? _c("toast-icon", {
                    ref: "icon",
                    staticClass: "icon",
                    attrs: { icon: _vm.value.options.icon }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.value.text != null
                ? _c("toast-content", {
                    ref: "content",
                    staticClass: "text",
                    attrs: { content: _vm.value.text }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.value.options.action != null
                ? _c(
                    "span",
                    { staticClass: "action", on: { click: _vm.onActionClick } },
                    [
                      _vm.value.options.action.icon != null
                        ? _c("toast-icon", {
                            ref: "actionIcon",
                            staticClass: "icon",
                            attrs: { icon: _vm.value.options.action.icon }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.value.options.action.text != null
                        ? _c("toast-content", {
                            ref: "actionText",
                            staticClass: "text",
                            attrs: { content: _vm.value.options.action.text }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }