var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "view" },
    [
      _c("section", { staticClass: "view-header" }, [_c("navbar")], 1),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "view-content" },
        [_c("router-view", { attrs: { id: "router-view" } })],
        1
      ),
      _vm._v(" "),
      _c("toasts", { ref: "toasts" }),
      _vm._v(" "),
      _c("service-worker-notifier")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }