var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "toasts-container" },
    _vm._l(_vm.messageQueue, function(message, index) {
      return _c("toast-message", {
        key: message.id,
        ref: "toasts",
        refInFor: true,
        on: { close: _vm.closeToast },
        model: {
          value: _vm.messageQueue[index],
          callback: function($$v) {
            _vm.$set(_vm.messageQueue, index, $$v)
          },
          expression: "messageQueue[index]"
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }