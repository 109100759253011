import BasePlugin, { PluginOptions, VueConstructor } from "../base";

export default class GetterPlugin extends BasePlugin<PluginOptions> 
{
    protected configure(Vue: VueConstructor): PromiseLike<void> 
    {
        Vue.prototype.$get = this.$get;

        return Promise.resolve();
    }

    private $get(object: any, path: string, defaultValue: any = undefined)
    {
        let pathParts = path.split('.');
        let result = object;
        for(let part of pathParts)
        {
            if (!result.hasOwnProperty(part))
            {
                return defaultValue;
            }
            
            result = result[part];
        }

        return result;
    }
}
