









































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseValueComponent from '../../../../../core/vue/base/value.vue';

@Component
export default class BulmaFormField extends Vue
{
    $refs: Vue['$refs'] & {
        inputElement: HTMLInputElement;
        inputField: BulmaFormField;
    }

    @Prop({type: Boolean, default: true})
    isValid: boolean;

    @Prop({type: Boolean, default: false})
    isSuccess: boolean;

    inputType = 'string';

    @Prop({type: Boolean, default: true})
    isEnabled: boolean;

    updateInputValue()
    {
        this.$emit('input', this.$refs.inputElement.value);
    }
    
    validate()
    {
        this.isValid = true;
    }

    focus(): void
    {
        this.$refs.inputElement.focus();    
    }

    // Class name getters
    /** Class names applied to the primary field element. */
    get fieldClassNames()
    {
        return {
            'has-addons': this.hasAddons,
            'has-addons-centered': this.hasAddonsCentered,
            'has-addons-right': this.hasAddonsRight,
            'is-grouped': this.isGrouped,
            'is-grouped-centered': this.isGroupedCentered,
            'is-grouped-right': this.isGroupedRight,
            'is-grouped-multiline': this.isGroupedMultiLine,
        }
    }

    get labelClassNames(): Object
    {
        return {
            'label': true,
            'test': true
        }
    }
    
    /** CSS class names applied to the control. */
    get controlClassNames() { return { 'has-icons-left': this.hasIconLeft, 'has-icons-right': this.hasIconRight, 'is-expanded': this.isExpanded }; }

    /** CSS class names for the input element. */
    get inputClassNames() { return {'is-success': this.isSuccess, 'is-danger': !this.isValid }; }

    /** CSS class names applied to label, icons, and the input element. */
    get sizeClassNames() { return { 'is-small': this.isSmall, 'is-medium': this.isMedium, 'is-large': this.isLarge }; }

    /** CSS class names for the help element. */
    get helpClassNames() { return {'has-text-grey': !this.isSuccess && this.isValid, 'has-text-success': this.isSuccess }};

    /** Resolves the field id. If the id property is not set, an automatically generated id using the label is returned. */
    get fieldId() { return this.id != null ?  this.id :  `id_${(this.labelText as string).replace(' ', '_')}`; }  

    // Style getters
    get wrapperFieldStyles() { return this.isExpanded ? { width: '100%' } : null; }

    // Value getters
    get hasHelpText() { return this.helpText != null; }
    get hasHelpSlot() { return this.$slots.help != null; }
    get hasHelpSlotOrHelpText() { return this.hasHelpSlot || this.hasHelpText; }
    // Addon getters
    get hasAddonLeft() { return !! this.$slots.addonsLeft; }
    get hasAddonRight() { return !! this.$slots.addonsRight; }
    get hasAddons() { return this.hasAddonLeft || this.hasAddonRight; }

    // Icon getters
    get hasIconLeft() { return !!this.$slots.iconLeft; }
    get hasIconRight() { return !!this.$slots.iconRight; }

    // Grouped getters
    get isGrouped() { return this.hasGroupedLeft || this.hasGroupedRight; }
    get hasGroupedLeft() { return !!this.$slots.groupedLeft; }
    get hasGroupedRight() { return !!this.$slots.groupedRight; }

    // Properties
    @Prop()
    id?: string;        

    @Prop({type: String, default: 'input'})
    inputClassName?: string;

    @Prop()
    labelText?: string;

    @Prop()
    placeholderText?: string;

    @Prop()
    helpText?: string;

    @Prop({type: Boolean})
    isSmall?: boolean;

    @Prop({type: Boolean})
    isMedium?: boolean;

    @Prop({type: Boolean})
    isLarge?: boolean;

    @Prop({type: Boolean})
    isExpanded?: boolean;

    @Prop({type: Boolean})
    hasAddonsCentered?: boolean;

    @Prop({type: Boolean})
    hasAddonsRight?: boolean;

    @Prop({type: Boolean})
    isGroupedCentered?: boolean;

    @Prop({type: Boolean})
    isGroupedRight?: boolean;

    @Prop({type: Boolean})
    isGroupedMultiLine?: boolean;

    @Prop({type: Boolean, default: true})
    isInlineLabel?: boolean;

    @Prop({type: String, default: ''})
    value: string;
}

