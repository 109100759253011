import Vue, {PluginObject, PluginFunction, VueConstructor} from "vue";
import VueRouter from 'vue-router';

import Shell from "./view/shell.vue";
import router from './config/router';
import store from './config/store';
import ToastsPlugin, {ToastsOptions} from '../core/plugin/toasts';
import GetterPlugin from "../core/plugin/getter";
import AppPlugin from "../core/plugin/app";
import InternationalizationPlugin from "../core/plugin/i18n";
import EuropressDatabase from "./database";
import Refresh from './component/misc/refresh.vue';
import LoadingSpinner from './component/misc/loading-spinner.vue';
import ValueOrNotAvailable from './component/misc/value-or-not-available.vue';
import SimplePagination from './component/pagination/simple/index.vue';
import BulmaIcon from './component/bulma/icon.vue';
import TopHeader from './component/top-header.vue';
import { isDescendantOf } from "../core/helper/dom";
import { Diagnostics, sendErrorDiagnostics, DEFAULT_DELAY } from "../core/diagnostics";
import { Component } from "vue-router/types/router";


function listenErrorEvent(name: string) {
    window.addEventListener(name, (e: ErrorEvent) => {
        console.error(e.error);
        sendErrorDiagnostics(name, DEFAULT_DELAY, e.error);
    });  
}

listenErrorEvent('error');
listenErrorEvent('unhandledrejection');

if (location.protocol === 'https:' || localStorage.getItem('force-vue-error-handler')) {
    Vue.config.errorHandler = (error, vm, info) => {
        sendErrorDiagnostics('vue.errorHandler', DEFAULT_DELAY, error, {info});
    };
}

const diagnostics = new Diagnostics();

export default class Application  {
    private _vueVM: Vue;
    public get vueVM() { return this._vueVM; }

    constructor() {
        diagnostics.add('index.html code executed', (window as any).__initialLoadTime as Date);
        document.getElementById('preload').remove();

        diagnostics.append(`Initializing application...`);
        const appPlugin = new AppPlugin();
        const i18nPlugin = new InternationalizationPlugin(appPlugin);
        const toastsPlugin = new ToastsPlugin();
        const getterPlugin = new GetterPlugin();
        
        const autoFocusDirectives: {element: HTMLInputElement, onFocus: (e: Event) => void}[] = [];

        Vue.component('simple-pagination', SimplePagination);
        Vue.component('bulma-icon', BulmaIcon);
        Vue.directive('auto-select-on-focus', {
            bind: (element: HTMLInputElement) =>
            {
                const onFocus = () =>
                {
                    element.select();
                };
                element.addEventListener('focus', onFocus);
                autoFocusDirectives.push({
                    element, onFocus
                })
            },
            unbind: (element: HTMLInputElement) =>
            {
                for (let pair of autoFocusDirectives)
                {
                    if (pair.element === element)
                    {
                        element.removeEventListener('focus', pair.onFocus);
                    }
                }
            }
        });

        Vue.directive('auto-focus', {
            inserted(el: HTMLInputElement) {
                el.focus();
            }
        });
        appPlugin.install({
            diagnostics,
            database: EuropressDatabase,
            router: router,
            store: store,
            toasts: toastsPlugin,
            i18n: i18nPlugin,
            authentication: {
                routes: {
                    signIn: 'auth-sign-in',
                    signOut: 'auth-sign-out',
                    afterSignedIn: 'home',
                    afterSignedOut: 'home',
                    noPermission: 'home'
                }
            }
        }).then(() =>
        {
            return Promise.all([
                toastsPlugin.install({}),
                getterPlugin.install({}),
                i18nPlugin.install({
                    app: appPlugin,
                    languageCode: appPlugin.userData.language
                }),
            ])
        })
        .then(() =>
        {
            Vue.component('refresh', Refresh);
            Vue.component('loading-spinner', LoadingSpinner);
            Vue.component('top-header', TopHeader);
            Vue.component('value-or-not-available', ValueOrNotAvailable);
            
            Vue.directive('refresh', (el, binding, vNode, oldVNode) =>
            {
                let options: {interval: number, value: any} = binding.value;
                let modifiers: {html: boolean} = binding.modifiers as any;

                const update = () =>
                {
                    let value = options.value instanceof Function ? options.value() : options.value;
                    
                    if (modifiers.html)
                    {
                        el.innerHTML = value;
                    }
                    else
                    {
                        el.textContent = value;
                    }
                };
                
                window.setInterval(update, options.interval);
                update();
            });
            
            diagnostics.append(`Starting application...`);

            this._vueVM = new Vue({
                el: '#app',
                render: h => h(Shell),
                router,
                store,
                created() {
                    appPlugin.$root = this;
                    diagnostics.append(`Application created.`);
                    diagnostics.flush('client_startup');
                }
            });

            appPlugin.$root = this._vueVM;
        });
    }
}