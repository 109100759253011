var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.$store.getters.synchronizationQueueSize > 0
      ? _c("main", [
          _c("i", {
            staticClass: "fas fa-spinner fa-spin",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(" \n        "),
          _c("span", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.$store.getters.synchronizationQueueSize))
          ]),
          _vm._v(" "),
          _c("i", {
            staticClass: "fas fa-arrow-up",
            attrs: { "aria-hidden": "true" }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }