import { Base } from "../../../core/database/models/base";

export class GenericForeignKeyModelMixin
{
    content_type_id: number;
    object_id: number;
}

export class SortIndexModelMixin
{
    sort_index: number;
}

export class GenericForeignKeyClientSyncMixin
{
    content_type_app_label: string;
    content_type_model: string;
    content_type_model_name: string;
}

export class DocumentationFile extends Base implements SortIndexModelMixin, GenericForeignKeyModelMixin, GenericForeignKeyClientSyncMixin
{
    title: string;
    file: string;

    content_type_id: number;
    object_id: number;
    sort_index: number;
    
    content_type_app_label: string;
    content_type_model: string;
    content_type_model_name: string;

    get fileName()
    {
        return this.file.substring(this.file.lastIndexOf('/') + 1);
    }

    matchesExtension(...extensions: string[]) {
        for (const ext of extensions) {
            if (this.file.endsWith(ext)) {
                return true;
            }
        }
        return false;
    }
    get isImageFile() {
        return this.matchesExtension('.jpg', '.jpeg', '.png', '.gif', '.bmp');
    }

    get isOpenable() {
        return this.isImageFile || this.matchesExtension('.pdf', '.txt');
    }
}

export class DocumentationLink extends Base implements SortIndexModelMixin, GenericForeignKeyModelMixin, GenericForeignKeyClientSyncMixin
{
    title: string;
    url: string;
    href_target: string;

    content_type_id: number;
    object_id: number;
    sort_index: number;
    
    content_type_app_label: string;
    content_type_model: string;
    content_type_model_name: string;
}

export class DocumentedModelMixin
{
    external_documentation_links?: DocumentationLink[];
    documentation_files?: DocumentationFile[];
}