import { Base } from "./base";


export class PushSyncMessage
{
    id?: number;
    action: string;
    timestamp?: Date;
    user_id?: number;
    uuid?: string;
    data?: {[key: string]: any};
}

export class SyncMessage extends PushSyncMessage
{
    id: number;
    timestamp: Date;
    user_id: number;
    uuid: string;
    data: {[key: string]: any};

    _data_length: number = null;
    _data_string: string = null;

    get data_string() {
        if (this._data_string == null) {
            this._data_string = JSON.stringify(this.data);
        }
        return this._data_string;
    }
    get data_length() {
        if (this._data_length == null) {
            this._data_length = this.data_string.length;
        }

        return this._data_length;
    }

    asLoggableObject(maxDataLength = 2048) {
        return {
            action: this.action,
            user_id: this.user_id,
            timestamp: this.timestamp,
            data_length: this.data_length,
            data_type: this.data_length <= maxDataLength ? 'full' : 'truncated',
            data: this.data_length <= maxDataLength ? this.data : {raw: this.data_string.substring(0, maxDataLength)},
        }
    }
}

export class SyncCommand extends Base
{
    action: SyncCommandAction;
    data: string;
}

export enum SyncCommandAction
{
    REMOVE_INSTANCE = 'remove_instance',
    FULL_RESET = 'full_reset',
    UPDATE_CONSTANCE = 'update_constance',
    ACTION_APP_UPDATE = 'app_update'
}

export interface SyncCommandData
{
    name: string;
    key: string;
}