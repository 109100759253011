










import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component({name: 'bulma-button'})
export default class Button extends Vue
{
    $refs: Vue['$refs'] & {
        loadingIcon: HTMLElement,
        icon: HTMLElement,
    }

    @Prop({type: Boolean, default: false}) isLoading: boolean;
}


