
















































    import Vue from 'vue';
    import {Component, Prop, Watch} from 'vue-property-decorator';
import { SyncMessage } from '../../../core/database/models/clientsync';
import { StoreActions } from '../../config/store';

    @Component({})
    export default class SyncQueue extends Vue 
    {
        $refs: Vue['$refs'] & {
            
        }

        isLoading = false;
        messages: SyncMessage[] = [];

        async mounted() {
            await this.loadMessages();
        }

        async loadMessages() {
            this.isLoading = true;
            this.messages = await this.$app.db.SyncMessage.toArray();
            this.$app.store.commit(StoreActions.SET_SYNCHRONIZATION_QUEUE_SIZE, await this.$app.db.SyncMessage.count());
            this.isLoading = false;
        }

        async deleteMessage(message: SyncMessage) {
            if (confirm(this.$ugettext('Are you sure? This cannot be undone.'))) {
                await this.$app.db.SyncMessage.delete(message.id);
                await this.loadMessages();
            }
        }
    }
