




import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class BaseValueComponent<ValueType extends ValueComponentData> extends Vue 
{        
    $refs: Vue['$refs'] & {
        
    }

    get valueDefaults(): ValueType { return <ValueType>{ } };

    created()
    {
        let valueDefaults = this.valueDefaults;
        for(let name in valueDefaults)
        {   
            if (this.value[name] == null && valueDefaults.hasOwnProperty(name))
            {
                this.$set(this.value, name, valueDefaults[name]);
            }
        }
    }

    @Prop({type: Object, default: () => {return {} }})
    value: ValueType;
}

export interface ValueComponentData
{
    [key: string]: any;
}
