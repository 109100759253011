export default class InitialLoading {
    public static progress: number = 0;

    public static postProgressMessage(message: string, increment: number) {
        this.progress += increment;

        window.dispatchEvent(new CustomEvent('initial-loading', {
            detail: {
                message: message,
                progress: this.progress,
                timestamp: new Date()
            }
        }))
    }
}
