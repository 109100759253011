import Vue, { Component } from "vue";
import { TechnicianMessage, TechnicianMessageRecipient, TechnicianMessageState } from "../../database/models/messaging";
import Dexie from "dexie";
import { UserAccount } from "../../../core/database/models/auth";
import { Location } from "../../../core/database/models/general";
import AppPlugin from "../../../core/plugin/app";
import { OrderPriority, Order, OrderPriorityGroup } from "../../database/models/order";
import EuropressDatabase from "../../database";
import { StoreActions } from "../../config/store";

export default Vue.extend({
    data() 
    {
        return <IMessagesMixin>{
            messages: [],
        }
    },

    computed: {
        hasMessages() { return this.$app.user.isAuthenticated && this.messages != null && this.messages.length > 0 }
    } as any,

    async mounted()
    {
        let messagesLoaded = false;

        this.loadMessages = async () =>
        {
            // Messages visible to the user logic:
            // no location and no recipients = global message (everyone with "Technician" group)
            // location = in that location and its children 
            // recipients = direct recipients only

            let now = new Date();
            let db = this.$app.db as EuropressDatabase;
            
            let account = await db.UserAccount.where('user_id').equals(this.$app.user.id).first() as UserAccount;
            const location = await (account.location_id != null ? db.Location.get(account.location_id) : null);

            const accessibleLocations = await (location != null ? Location.get_family(location, db.Location.where('id').above(0)).toArray() : null);
            // Currently visible messages that tare in "sent" state
            let [messages] = [
                await db.TechnicianMessage
                    .filter(o => o.state == TechnicianMessageState.SENT && o.visibility_starts_at <= now && o.visibility_ends_at >= now)
                    .toArray()
            ];
            let messageIds = messages.map(o => o.id);
            
            let [recipients, confirmations, technicianGroupCount] = [
                // Recipients targeting current user
                await db.TechnicianMessageRecipient
                    .where('message_id').anyOf(messageIds)
                    .filter(o => o.user_id == this.$app.user.id)
                    .toArray(),
                await db.TechnicianMessageConfirmation
                    .where('message_id').anyOf(messageIds)
                    .filter(o => o.user_id == this.$app.user.id)
                .toArray(),
                await db.Group.where('id').anyOf(this.$app.user.groups)
                    .and(o => o.name == 'Technician')
                .count(),
            ];

            for (let message of messages) {
                message.recipients = recipients.filter(o => o.message_id == message.id);
            }

            let seenMessageIds = confirmations.map(o => o.message_id);
            let recipientMessageIds = recipients.map(o => o.message_id);
            
            function shouldShowMessage(message: TechnicianMessage)
            {
                let isTechnician = technicianGroupCount > 0;
                let isUnseen = seenMessageIds.indexOf(message.id) == -1;

                if (!isUnseen) {
                    return false;
                } else if (message.location_id == null && message.recipients.length == 0) {
                    return true;
                } else if (message.location_id != null && accessibleLocations != null) {
                    const isInLocationHierarchy = accessibleLocations.findIndex(o => o.id == message.location_id) != -1;
                    return isInLocationHierarchy;
                } else if (message.recipients.length > 0) {
                    const isDirectRecipient = message.recipients.findIndex(o => o.message_id == message.id && o.user_id == account.user_id) != -1;
                    return isDirectRecipient;
                } else {
                    return false;
                }
            }

            messages = messages.filter(shouldShowMessage);
            
            let [priorities, locations] = [
                await db.OrderPriority.where('id').anyOf(messages.map(o => o.priority_id)).toArray(),
                await db.Location.where('id').anyOf(messages.map(o => o.location_id).filter(o => o != null)).toArray(),
            ];

            for(let message of messages)
            {
                message.priority = priorities.find(o => o.id == message.priority_id);
                message.location = locations.find(o => o.id == message.location_id);
            }
            
            messages.sort((a, b) => {
                let aPriority = a.priority as OrderPriority;
                let bPriority = b.priority as OrderPriority;
                
                if (aPriority.group == OrderPriorityGroup.HIGH && bPriority.group == OrderPriorityGroup.HIGH)
                    return b.id - a.id;
                else if (aPriority.group == OrderPriorityGroup.HIGH)
                    return -1;
                else if(bPriority.group == OrderPriorityGroup.HIGH)
                    return 1;
                else
                    return b.id - a.id;
            });

            this.$store.commit(StoreActions.SET_UNSEEN_MESSAGE_COUNTS, {
                normal: messages.filter(o => (o.priority as OrderPriority).group == OrderPriorityGroup.NORMAL).length,
                high: messages.filter(o => (o.priority as OrderPriority).group == OrderPriorityGroup.HIGH).length
            });

            this.messages = messages;
        };

        const tryLoadMessages = async () =>
        {
            if (this.$app.user.isAuthenticated)
            {
                await this.loadMessages();
                messagesLoaded = true;
                return;
            }
            
            messagesLoaded = false;
        };
        
        await tryLoadMessages();

        if (!messagesLoaded)
        {
            this.$store.watch((state: any) => state.user, () => 
            {
                if (!messagesLoaded)
                {
                    tryLoadMessages();
                }
            });
        }
    }
});

export interface IMessagesMixin
{
    messages: TechnicianMessage[] | null;
    hasMessages: boolean;
    loadMessages: () => void;
}
