export class OfflineError extends Error
{
    constructor(message?: string) 
    {
        super(message);
        Object.setPrototypeOf(this, OfflineError.prototype);
    }
}

export class ServerError extends Error
{
    public readonly status: number;

    constructor(status: number, message?: string) 
    {
        super(message);
        this.status = status;
        Object.setPrototypeOf(this, ServerError.prototype);
    }
}