var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("main", [
      _c(
        "section",
        { staticClass: "hero is-primary", attrs: { id: "app-navbar-hero" } },
        [
          _c("div", { staticClass: "hero-body" }),
          _vm._v(" "),
          _c("div", { staticClass: "hero-foot" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "nav",
                {
                  staticClass: "navbar is-primary",
                  attrs: {
                    role: "navigation",
                    "aria-label": "main navigation",
                    id: "app-navbar"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "navbar-brand" },
                    [
                      _c("sync-queue-icon"),
                      _vm._v(" "),
                      _vm.$store.getters.isSynchronizing &&
                      _vm.$store.getters.isSynchronizerOverlayVisible
                        ? _c("sync-icon")
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-item",
                          attrs: { to: { name: "home" } }
                        },
                        [
                          _c("div", { staticStyle: { width: "191px" } }, [
                            _vm.constance != null &&
                            _vm.constance
                              .CORE_GENERAL_DISABLE_CUSTOMER_BRANDING != true
                              ? _c("img", {
                                  attrs: {
                                    src: "/static/image/navbar-brand-light.svg"
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src:
                                      "/static/image/default/navbar-brand-light.png"
                                  }
                                }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "powered-by" },
                              [
                                _vm.constance != null &&
                                _vm.constance
                                  .CORE_GENERAL_DISABLE_CUSTOMER_BRANDING !=
                                  true
                                  ? [
                                      _vm._v(
                                        "\n                                        Powered by Bluugo Tracking Cloud™\n                                    "
                                      )
                                    ]
                                  : void 0
                              ],
                              2
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "navbar-item",
                          attrs: { to: { name: "messages" } }
                        },
                        [_c("messages-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _vm._t("navbar-brand"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "navbar-burger",
                          class: { "is-active": _vm.isMenuExpanded },
                          on: { click: _vm.toggleCollapsedMenu }
                        },
                        [
                          _c("span"),
                          _vm._v(" "),
                          _c("span"),
                          _vm._v(" "),
                          _c("span")
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "navbar-menu",
                      class: { "is-active": _vm.isMenuExpanded }
                    },
                    [
                      _c("div", { staticClass: "branding" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "navbar-start" },
                        [_vm._t("navbar-start")],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "navbar-center" },
                        [_vm._t("navbar-center")],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "navbar-end" },
                        [
                          _vm._t("navbar-end"),
                          _vm._v(" "),
                          false
                            ? _c(
                                "a",
                                {
                                  staticClass: "navbar-item",
                                  on: { click: _vm.toggleFullScreen }
                                },
                                [
                                  _c("span", { staticClass: "icon" }, [
                                    !_vm.isFullScreen
                                      ? _c("i", {
                                          staticClass: "fas fa-expand",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isFullScreen
                                      ? _c("i", {
                                          staticClass: "fas fa-compress",
                                          attrs: { "aria-hidden": "true" }
                                        })
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$ugettext("Fullscreen")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canChangeLocation
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "navbar-item",
                                  attrs: { to: { name: "changeLocation" } }
                                },
                                [
                                  _c("span", { staticClass: "icon" }, [
                                    _c("i", {
                                      staticClass: "fas fa-map-marker-alt",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$ugettext("Change location"))
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$app.user.isAuthenticated
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "navbar-item",
                                  attrs: { to: { name: "synchronize" } }
                                },
                                [
                                  _c("span", { staticClass: "icon" }, [
                                    _c("i", {
                                      staticClass: "fas fa-cloud-download-alt",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$ugettext("Synchronize")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$app.user.isAuthenticated
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "navbar-item",
                                  attrs: { to: { name: "auth-sign-in" } }
                                },
                                [
                                  _c("span", { staticClass: "icon" }, [
                                    _c("i", {
                                      staticClass: "fas fa-sign-in-alt",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$ugettext("Sign in")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$app.user.isAuthenticated
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "navbar-item",
                                  attrs: { to: { name: "auth-sign-out" } }
                                },
                                [
                                  _c("span", { staticClass: "icon" }, [
                                    _c("i", {
                                      staticClass: "fas fa-sign-out-alt",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$ugettext("Sign out")))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            {
                              staticClass: "navbar-item",
                              attrs: { to: { name: "about" } }
                            },
                            [
                              _c("span", { staticClass: "icon" }, [
                                _c("i", {
                                  staticClass: "fas fa-info-circle",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$ugettext("About")))
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }