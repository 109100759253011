


































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BulmaFormField from '../../component/bulma/form/field/base.vue';
import BulmaEmailField from '.../../component/bulma/form/field/email.vue';
import BulmaPasswordField from '../../component/bulma/form/field/password.vue';
import BulmaCheckboxField from '../../component/bulma/form/field/checkbox.vue';
import ModalCard from '../../component/bulma/modal-card.vue';
import BulmaButton from '../../component/bulma/button.vue';
import { AttributeType, CurrentUser } from '../../../core/database/models/attribute';

@Component({components: {BulmaFormField, BulmaPasswordField, BulmaCheckboxField, ModalCard, BulmaButton}})
export default class AuthenticationSignOut extends Vue 
{
    $refs: Vue['$refs'] & {

    }

    isLoading = false;

    resetData = false;

    confirmSignOut() {
        this.isLoading = true;
        setTimeout(() => {
            this.$app.signOut({resetData: this.resetData});
        });
    }
}
