var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "view",
      attrs: { "foo-key": _vm.$i18n.activeAvailableLanguage }
    },
    [
      _c("top-header", { staticClass: "has-text-centered view-header" }, [
        _c("i", {
          staticClass: "fas fa-sign-in-alt",
          attrs: { slot: "icon", "aria-hidden": "true" },
          slot: "icon"
        }),
        _vm._v(
          "\n        " +
            _vm._s(_vm.$ugettext("Sign in - Authentication")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "view-content section columns is-centered" }, [
        _c(
          "div",
          { staticClass: "column is-6-tablet is-4-desktop is-4-fullhd" },
          [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.authenticate.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "label",
                  { staticClass: "label", attrs: { for: "username" } },
                  [_vm._v(_vm._s(_vm.$ugettext("Username")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control has-icons-left" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.username,
                          expression: "user.username"
                        }
                      ],
                      staticClass: "input",
                      attrs: {
                        id: "username",
                        type: "text",
                        placeholder: _vm.$ugettext("john.doe"),
                        autocapitalize: "off",
                        autocomplete: "username",
                        disabled: _vm.isLoading
                      },
                      domProps: { value: _vm.user.username },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "username", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "help" }, [
                    _vm._v(_vm._s(_vm.$ugettext("Tracking Cloud username")))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "password" } },
                    [_vm._v(_vm._s(_vm.$ugettext("Password")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "field has-addons has-no-margin-bottom" },
                    [
                      _c(
                        "div",
                        { staticClass: "control has-icons-left is-expanded" },
                        [
                          _vm._m(1),
                          _vm._v(" "),
                          _vm.passwordInputType === "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.password,
                                    expression: "user.password"
                                  }
                                ],
                                ref: "passwordInput",
                                staticClass: "input",
                                staticStyle: { "z-index": "initial" },
                                attrs: {
                                  id: "password",
                                  autocomplete: "current-password",
                                  disabled: _vm.isLoading,
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.user.password)
                                    ? _vm._i(_vm.user.password, null) > -1
                                    : _vm.user.password
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.user.password,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user,
                                            "password",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user,
                                            "password",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.user, "password", $$c)
                                    }
                                  }
                                }
                              })
                            : _vm.passwordInputType === "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.password,
                                    expression: "user.password"
                                  }
                                ],
                                ref: "passwordInput",
                                staticClass: "input",
                                staticStyle: { "z-index": "initial" },
                                attrs: {
                                  id: "password",
                                  autocomplete: "current-password",
                                  disabled: _vm.isLoading,
                                  type: "radio"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.password, null)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.user, "password", null)
                                  }
                                }
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.password,
                                    expression: "user.password"
                                  }
                                ],
                                ref: "passwordInput",
                                staticClass: "input",
                                staticStyle: { "z-index": "initial" },
                                attrs: {
                                  id: "password",
                                  autocomplete: "current-password",
                                  disabled: _vm.isLoading,
                                  type: _vm.passwordInputType
                                },
                                domProps: { value: _vm.user.password },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.user,
                                      "password",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "button", disabled: _vm.isLoading },
                            on: {
                              click: function($event) {
                                _vm.passwordInputType =
                                  _vm.passwordInputType == "password"
                                    ? "text"
                                    : "password"
                                _vm.$refs.passwordInput.focus()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _vm.passwordInputType == "password"
                                ? _c("i", {
                                    staticClass: "far fa-eye",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                : _c("i", {
                                    staticClass: "far fa-eye-slash",
                                    attrs: { "aria-hidden": "true" }
                                  })
                            ])
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "help" }, [
                    _vm._v(_vm._s(_vm.$ugettext("Tracking Cloud password.")))
                  ])
                ]),
                _vm._v(" "),
                _vm.isUsernameOrPasswordInvalid
                  ? _c("div", { staticClass: "has-text-danger" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$ugettext("Username or password is invalid.")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("hr", { staticClass: "is-marginless" }),
                _vm._v(" "),
                _c("div", { staticClass: "is-size-7" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: _vm.passwordResetLink(), target: "_blank" }
                    },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$ugettext("I forgot my password.")))
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "field is-grouped is-grouped-right" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        attrs: { disabled: _vm.isLoading }
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _vm.isLoading
                            ? _c("i", {
                                staticClass: "fas fa-spinner fa-spin",
                                attrs: { "aria-hidden": "true" }
                              })
                            : _c("i", {
                                staticClass: "fas fa-sign-in-alt",
                                attrs: { "aria-hidden": "true" }
                              })
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$ugettext("Sign in")))])
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.showPasswordResetModal
        ? _c(
            "modal-card",
            {
              ref: "passwordResetModal",
              on: {
                submit: _vm.onPasswordResetModalSubmit,
                close: _vm.passwordResetModalClosed
              },
              model: {
                value: _vm.passwordResetModal,
                callback: function($$v) {
                  _vm.passwordResetModal = $$v
                },
                expression: "passwordResetModal"
              }
            },
            [
              _c("header", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$ugettext("Password recovery")))
              ]),
              _vm._v(" "),
              _c(
                "main",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _c(
                    "bulma-email-field",
                    {
                      ref: "passwordResetEmailField",
                      attrs: {
                        labelText: "Email",
                        id: "email",
                        placeholderText: "John@Doe@company.com",
                        "is-expanded": ""
                      },
                      model: {
                        value: _vm.user.passwordResetEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "passwordResetEmail", $$v)
                        },
                        expression: "user.passwordResetEmail"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "help",
                          class: { "is-hidden": _vm.isPasswordResetEmailValid },
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c("i", {
                              staticClass: "fas fa-exclamation-circle",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$ugettext(
                                  "Please enter a valid email address."
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { attrs: { slot: "help" }, slot: "help" }, [
                        _c("span", { staticClass: "icon" }, [
                          _c("i", {
                            staticClass: "fas fa-info-circle",
                            attrs: { "aria-hidden": "true" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$ugettext(
                                  "Tracking Cloud account email address"
                                )
                              ) +
                              " "
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("footer", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: { "is-loading": !_vm.passwordResetModal.isEnabled },
                    attrs: { disabled: !_vm.passwordResetModal.isEnabled }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$ugettext("Submit")) +
                        "\n            "
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fa fa-user", attrs: { "aria-hidden": "true" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-lock", attrs: { "aria-hidden": "true" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fas fa-exclamation-triangle",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "far fa-question-circle",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }