










import Vue from 'vue';
import Dexie from 'dexie';
import {Component, Prop} from 'vue-property-decorator';
import { TechnicianMessageRecipient, TechnicianMessage } from '../../database/models/messaging';
import { UserAccount } from '../../../core/database/models/auth';
import MessagesMixin,{ IMessagesMixin } from '../mixins/messages';
import { OrderPriority, OrderPriorityGroup } from '../../database/models/order';

@Component({
    mixins: [MessagesMixin]
})
export default class MessagesIcon extends Vue implements IMessagesMixin
{
    hasMessages: boolean;
    messages: null | TechnicianMessage[];
    loadMessages: () => void;
}   
