var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isVisible
      ? _c("main", [
          _c("div", { staticClass: "spinner" }, [
            _c("i", {
              staticClass: "fa fa-spinner fa-spin",
              attrs: { "aria-hidden": "true" }
            })
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n        " + _vm._s(_vm.$ugettext("Loading...")) + "\n    "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }