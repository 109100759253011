





import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class Refresh extends Vue 
{        
    $refs: Vue['$refs'] & {
        content: HTMLSpanElement;
    }

    @Prop({type: Number, default: 1000})
    interval: number;

    @Prop({type: Boolean, default: true})
    escape: boolean;

    @Prop({default: ''})
    content: any;

    intervalHandle: number;

    mounted()
    {
        const update = () =>
        {
            let value = this.content instanceof Function ? this.content() : this.content;
            if (this.escape)
            {
                this.$refs.content.textContent = value;
            }
            else
            {
                this.$refs.content.innerHTML = value;
            }
        };

        this.intervalHandle = window.setInterval(update, this.interval);
        update();
    }

    destroyed()
    {
        window.clearInterval(this.intervalHandle);
    }
}
