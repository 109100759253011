import { Base } from "../../../core/database/models/base";
import { Stock } from "./general";
import { User } from "../../../core/database/models/auth";
import { Location } from "./tracking";

export class MaintenanceAccount extends Base {
    user_id: number;
    customer_id: number;
    target_invoice: number;
    invoice_unit: string;
    person_number: string;
    stock_id: number;

    user?: User;
    stock?: Stock;
    handler: string;
    country_id: number;
    organization_level_id: number;


    country: Location;
    organization_level: Location;
}