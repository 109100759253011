var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "bulma-form-field",
    _vm._b(
      { ref: "inputField", on: { input: _vm.updateInputValue } },
      "bulma-form-field",
      _vm.$props,
      false
    ),
    [
      _c("i", {
        staticClass: "fas fa-lock",
        attrs: { slot: "iconLeft", "aria-hidden": "true" },
        slot: "iconLeft"
      }),
      _vm._v(" "),
      _vm._t("iconRight", null, { slot: "iconRight" }),
      _vm._v(" "),
      _vm._t("addonsLeft", null, { slot: "addonsLeft" }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          class: { "is-info": _vm.isPasswordVisible },
          attrs: { slot: "addonsRight", type: "button" },
          on: { click: _vm.togglePasswordVisibility },
          slot: "addonsRight"
        },
        [
          _c("span", { staticClass: "icon" }, [
            _c("i", {
              staticClass: "fas",
              class: {
                "fa-eye": !_vm.isPasswordVisible,
                "fa-eye-slash": _vm.isPasswordVisible
              },
              attrs: { "aria-hidden": "true" }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm._t("control", null, { slot: "control" }),
      _vm._v(" "),
      _vm._t("groupedLeft", null, { slot: "groupedLeft" }),
      _vm._v(" "),
      _vm._t("groupedRight", null, { slot: "groupedRight" }),
      _vm._v(" "),
      _vm._t("error", null, { slot: "error" }),
      _vm._v(" "),
      _vm._t("help", null, { slot: "help" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }