
import Vue,{ VNode } from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class ValueOrNotAvailable extends Vue 
{        
    @Prop()
    hasContent: any;

    @Prop({default: undefined})
    content: any;

    cachedRenderableContent: any | VNode;

    get renderableContent(): any | VNode
    {
        if (this.cachedRenderableContent === undefined)
        {
            if (this.content !== undefined)
            {
                let content = this.content;
                if (content instanceof Function)
                {
                    content = content();
                }

                this.cachedRenderableContent = content;
            }
            else
            {
                this.cachedRenderableContent = this.$slots.default[0];
            }
        }

        return this.cachedRenderableContent;
    }

    get hasRenderableContent()
    {
        /**
         * Check if slot is not empty (text is not empty or null (trim)), children is null or tag is null
         * 
         */
        if (this.hasContent instanceof Function)
        {
            return this.hasContent();
        }
        else if (typeof this.hasContent === 'boolean')
        {
            return this.hasContent;
        }
        else
        {
            let content = this.renderableContent;
            if (content == null)
            {
                return false;
            }
            if (typeof content === 'string')
            {
                return content.length > 0;
            }
            else if (content.constructor.name == 'VNode')
            {
                let node = content as VNode;
                return node.text.trim().length > 0 || node.tag != null || node.children != null;
            }

            return true;
        }
    }

    render(createElement: typeof Vue.prototype.$createElement)
    {
        if (!this.hasRenderableContent)
        {
            return createElement('i', {
                class: ['has-text-grey'],
                domProps: {
                    textContent: this.$ugettext('N/A')
                }
            });
        }

        let content = this.renderableContent;
        if (content.constructor.name != 'VNode')
        {
            return createElement('span', `${content}`);
        }

        return content;
    }
}
