
























import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BulmaFormField from './base.vue';

@Component({components: {BulmaFormField}, extends: BulmaFormField})
export default class BulmaPasswordField extends BulmaFormField 
{       
    $refs: BulmaFormField['$refs'] & {
        inputField: BulmaFormField
    }

    isPasswordVisible = false;

    mounted()
    {
        this.$refs.inputField.inputType = 'password';
    }

    togglePasswordVisibility()
    {
        let inputType = this.$refs.inputField.inputType;
        this.$refs.inputField.inputType = inputType == 'password' ? 'text' : 'password'; 
        this.isPasswordVisible = this.$refs.inputField.inputType === 'text';
        this.focus();
    }

    updateInputValue()
    {
        this.$emit('input', this.$refs.inputField.$refs.inputElement.value);
    }
    
    focus(): void
    {
        this.$refs.inputField.focus();    
    }
}
