






















































































































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BulmaFormField from '../../component/bulma/form/field/base.vue';
import BulmaEmailField from '../../component/bulma/form/field/email.vue';
import BulmaPasswordField from '../../component/bulma/form/field/password.vue';
import ModalCard from '../../component/bulma/modal-card.vue';
import BulmaButton from '../../component/bulma/button.vue';
import { AttributeType, CurrentUser } from '../../../core/database/models/attribute';

@Component({components: {BulmaFormField, BulmaPasswordField, BulmaEmailField, ModalCard, BulmaButton}})
export default class AuthenticationSignIn extends Vue 
{
    isLoading = false;
    showPasswordResetModal = false;
    isUsernameOrPasswordInvalid = false;
    isPasswordResetEmailValid = true;
    isPasswordResetModalLoading = false;

    passwordInputType = 'password';

    $refs: Vue['$refs'] & {
        signInButton: BulmaButton;
        passwordField: BulmaPasswordField;
        passwordResetEmailField: BulmaEmailField;
    }

    mounted() {
        this.updatePasswordResetModalVisibilityFromRouteMeta();
    }

    passwordResetLink() {
        return `${this.$app.config.urls.trackingcloud}/profile/password_reset_client/?next=${encodeURIComponent(location.href)}`
    }

    @Watch('$route')
    updatePasswordResetModalVisibilityFromRouteMeta() {
        this.$set(this, 'showPasswordResetModal', this.$get(this.$route, 'meta.showModal', false));
    }

    authenticate() {
        this.isLoading = true;

        this.$app.authenticate({
            username: this.user.username, 
            password: this.user.password,
            checksum: this.user.username.split('').concat(this.user.password.split('')).map((c) => c.charCodeAt(0)).reduce((a, b) => a + b, 0)
        }).then((result) => {
            if (!result.isSuccess) {
                this.$toasts.show(result.errorMessage as string, {
                    icon: 'fas fa-exclamation-triangle has-text-danger',
                    duration: 5000,
                    alignment: 'right'
                });
                this.isLoading = false;
            }
        });
    }

    passwordResetModalClosed()
    {
        this.showPasswordResetModal = false;
        this.$router.back();
    }

    onPasswordResetModalSubmit()
    {
        (this.$refs.passwordResetEmailField as any).validate();

        this.isPasswordResetEmailValid = (this.$refs.passwordResetEmailField as any).isValid;

        if (this.isPasswordResetEmailValid)
        {
            //this.passwordResetModal.isEnabled = false;
            (this.$refs.passwordResetEmailField as any).isEnabled = false;
            window.setTimeout(() =>
            {
                this.passwordResetModalClosed();
            }, 1000);
        }

        return false;
    }

    @Prop({type: Object, default: () => { return {username: '', password: '', passwordResetEmail: ''}}})
    user: SignInUserData;
}

export interface SignInUserData
{
    username: string;
    password: string;
    passwordResetEmail: string;
}
