






















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import TabPage from './page.vue';
import {TabBrowseDirection} from '.';

@Component
export default class TabContainer extends Vue
{
    $refs: Vue['$refs'] & {
        header: HTMLElement;
        tabHeader: HTMLElement[];
        pages: HTMLElement;
    }

    @Prop({type: Boolean})
    isDisabled: boolean;

    $children: TabPage[];

    pages: TabPage[] = [];
    activePageIndex = 0;
    tabBrowseDirection = TabBrowseDirection.FORWARD;

    created()
    {
        this.pages = this.$children;
    }

    mounted()
    {
        this.activePageIndex = this.$children.indexOf(this.$children.find(o => (o as any).isActive) as TabPage);
        
        this.$nextTick().then(() =>
        {
            this.$refs.header.scrollLeft = this.$refs.tabHeader[this.activePageIndex].offsetLeft;
        });
    }

    tabClicked(i: number)
    {
        // Prevent navigation if component is in disabled state
        if (this.isDisabled) {
            return;
        }

        // Resolve direction
        this.tabBrowseDirection = i > this.activePageIndex ? TabBrowseDirection.FORWARD : TabBrowseDirection.BACKWARD;

        // Update direction
        for(let child of this.$children)
        {
            (child as any).direction = this.tabBrowseDirection;
        }

        // Wait one tick and then update pages (otherwise direction will not sync!)
        this.$nextTick().then(() =>
        {   
            for(let child of this.$children)
            {
                (child as any).isVisible = false;
            }

            (this.$children[i] as any).isVisible = true;
            this.activePageIndex = i;
            this.$refs.tabHeader[i].scrollIntoView();
            this.$emit('navigate', this.activePageIndex);
        });
    }
}
