/**
 * Gets a deep property from an object using a dot-separated path.
 */
export function getProperty<T = string>(object: any, path: string, defaultValue: T): T
{
    if (object == null)
    {
        return defaultValue;
    }

    let pathParts = path.split('.');
    let result = object;
    
    for(let part of pathParts)
    {
        if (!result.hasOwnProperty(part))
        {
            return defaultValue;
        }
        
        result = result[part];
    }

    return result;
}

export function jsonStringifyProperties(obj: any, propertyMaxLength = 4096) {
    const result = {};

    for (const propertyName in obj) {
        const value = JSON.stringify(obj[propertyName]);

        if (value !== undefined) {
            if (value.length > propertyMaxLength) {
                result[propertyName] = {
                    type: 'raw',
                    status: `truncated, max length of ${propertyMaxLength} exceeded.`,
                    data: value.substring(0, propertyMaxLength)
                }
            } else {
                result[propertyName] = value;
            }
        }
    }

    return result;
}