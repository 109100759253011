import { ExtBase, Model, Base } from "../../../core/database/models/base";
import { Location} from '../../../core/database/models/general';
import { User } from "../../../core/database/models/auth";
import { DocumentationLink, DocumentationFile, DocumentedModelMixin } from "./documentation";

export class NameMixin
{
    name: string;
}

export class DescriptionMixin
{
    description: string;
}

export class NameAndDescriptionMixin extends NameMixin implements DescriptionMixin
{
    description: string;
}

export class ExtBaseNameMixin extends ExtBase implements NameMixin
{
    name: string;
    name_fixed: string;
}

export class ExtBaseNameAndDescriptionMixin extends ExtBaseNameMixin implements DescriptionMixin 
{
    description: string;
}

export class SerialNumberMixin
{
    serial_number: string;
    serial_number_fixed: string;
}

export class Equipment extends ExtBase implements NameMixin, DocumentedModelMixin
{
    name: string;
    type_id: number;
    image: string;

    type?: EquipmentType;
    
    external_documentation_links?: DocumentationLink[];
    documentation_files?: DocumentationFile[];

    toString()
    {
        return `${this.name}`;
    }
}

export class EquipmentType extends ExtBaseNameAndDescriptionMixin 
{
    
}

export abstract class BaseItem extends ExtBaseNameAndDescriptionMixin {
    type: ItemType;
    group_id: number;
    quantity_unit_id: number;

    quantity_unit?: ItemQuantityUnit;
    group?: ItemGroup;
    active: boolean;
    additional_search_text: string;

    public static isAvailableOnClient(item: BaseItem) {
        return (
            (item.active === undefined || item.active)
        );
    }
}

export enum ItemType
{
    SPARE_PART = 'spare-part',
    SERVICE = 'service'
}

export class ItemTypeModelMixin
{
    type: ItemType;
}

export class ItemGroup extends ExtBaseNameAndDescriptionMixin
{

}

export class ItemQuantityUnit extends ExtBaseNameAndDescriptionMixin implements ItemTypeModelMixin
{
    type: ItemType;
    unit: string;
}

export class Item extends BaseItem
{
    location_id: number;
    location?: Location;
    active: boolean;

    // this is for ui to transport default item quantity
    quantity: any;
    
    sub_item_assignments?: SubItemAssignment[];

    toString()
    {
        return `${this.name} - ${this.ext_id}`;
    }

    stock_items?: StockItem[];
}

export class SubItemAssignment extends Base implements QuantifiableModelMixin
{
    parent_id: number;
    child_id: number;

    parent?: Item;
    child?: Item;

    quantity: number;
}

export class SparePart extends Item
{
    type = ItemType.SPARE_PART;
    // Do not add functionality to this class! $app.db.SparePart returns instances of Item!
}

export class ServiceItem extends Item
{
    type = ItemType.SERVICE;

    // Do not add functionality to this class! $app.db.SparePart returns instances of Item!
}

export class TaskLike extends ExtBaseNameAndDescriptionMixin
{

}

export class QuantifiableModelMixin extends Model
{
    quantity: number;
}

export class SparePartAssignmentLike extends Base implements QuantifiableModelMixin 
{
    quantity: number;
    spare_part_id: number;

    _spare_part?: SparePart;
    get spare_part(): SparePart | undefined { return this._spare_part; }
    set spare_part(value: SparePart | undefined)
    {
        this._spare_part = value;
        if (value != null)
        {
            this.spare_part_id = value.id;
        }
    }

    toString() {
        return `${this.spare_part.name} - ${this.spare_part.ext_id}`;
    }
}

export class ServiceItemAssignmentLike extends Base implements QuantifiableModelMixin 
{
    quantity: number;
    service_item_id: number;

    _service_item?: ServiceItem;
    get service_item(): ServiceItem | undefined { return this._service_item; }
    set service_item(value: ServiceItem | undefined)
    {
        this._service_item = value;
        if (value != null)
        {
            this.service_item_id = value.id;
        }
    }

    toString() {
        return `${this.service_item.name} - ${this.service_item.ext_id}`;
    }
}

export class TaskStepLike extends ExtBaseNameAndDescriptionMixin
{
    status_id: number;

    private _status?: StepStatus;
    get status(): StepStatus | undefined { return this._status; }
    set status(value: StepStatus | undefined)
    {
        this._status = value;
        if (value != null)
        {
            this.status_id = value.id;
        }
    }
}

export class SortOrderModelMixin extends Model
{
    sort_order: number;
}

export class ServiceItemQuantityUnit extends ItemQuantityUnit
{
    unit: string;
    type: ItemType;
}

export class SparePartQuantityUnit extends ItemQuantityUnit
{
    unit: string;
    type: ItemType;
}

export interface IUserMixin
{
    user_id?: number;
    user?: User;
}

export class UserMixin implements IUserMixin
{
    user_id?: number;
    
    protected _user?: User;
    public get user(): User | undefined { return this._user; }
    public set user(value: User | undefined)
    {
        this._user = value;
        if (value != null)
        {
            this.user_id = value.id;
        }
    }
}

export enum StepStatusType
{
    NEW = 'new',
    DEFAULT = 'default',
    COMPLETED = 'completed',
}

export class StepStatus extends ExtBaseNameAndDescriptionMixin
{
    type: StepStatusType;
    name_sv: string;
    name_no: string;
    name_en: string;

    _name: string;
    get name() { return this[`name_${(window as any).__language__}`] || this.name_en || this._name; }
    set name(value: string) {this._name = value;}
}


export class ItemPriceMixin
{
    original_unit_price: number;
    total_price: number;
    price_unit: string;
}

export class Stock extends ExtBaseNameAndDescriptionMixin
{
    active: boolean;
}

export class StockItem extends Base implements ItemTypeModelMixin
{
    stock_id: number;
    item_id: number;
    purchase_cost: number;
    type: ItemType;

    stock?: Stock;
}

export class EquipmentSparePartAssignment extends Base
{
    equipment_id: number;
    quantity: number;
    spare_part: string;
}

export class EquipmentServiceItemAssignment extends Base
{
    equipment_id: number;
    quantity: number;
    service_item: string;
}