export class Model
{
    id: number;
    is_offline?: boolean;
    offline_message_uuid?: string;

    _is_saved = true;
    get is_saved() { return this._is_saved != null ? this._is_saved : true; }
    set is_saved(value: boolean) { this._is_saved = value; }

    /** Executed on cientsync put */
    preProcessInstance()
    {
        // Clear offline status
        this.is_offline = false;
        this.offline_message_uuid = null;

        const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
        for (let key in this)
        {
            let value = this[key];
            if (typeof value == 'string' && dateFormat.test(value))
            {
                this[key] = new Date(value) as any;
            }
        }
    }
}

export class Base extends Model
{
    created_at: Date;
    modified_at: Date;
}

export class ExtBase extends Base
{
    ext_id: string;
}