














import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import BulmaFormField from './base.vue';

@Component({components: {BulmaFormField}})
export default class BulmaCheckboxField extends BulmaFormField 
{       
    $refs: BulmaFormField['$refs'] & {
        inputField: BulmaFormField
    }

    get labelClassNames(): Object
    {
        return {
            'label': false,
            'foobar': true
        }
    }

    mounted()
    {
        this.$refs.inputField.inputType = 'checkbox';
    }
    
    updateInputValue()
    {
        this.$emit('input', this.$refs.inputField.$refs.inputField.value);
    }        
    
    @Prop({type: String, default: 'checkbox'})
    inputClassName?: string;

    @Prop({type: Boolean, default: false})
    isInlineLabel?: boolean;
}
