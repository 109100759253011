var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "top-header" } }, [
    _c("div", { staticClass: "is-size-6" }, [
      _c("div", { staticClass: "container header-container" }, [
        _c(
          "span",
          { staticClass: "header-icon icon has-text-grey" },
          [_vm._t("icon")],
          2
        ),
        _vm._v(" "),
        _c("span", { staticClass: "header-content" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c("span", { staticClass: "header-suffix" }, [_vm._t("suffix")], 2)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }