import { Database } from "../../database";
import { AttributeType, LastSynchronization, UserData } from "../../database/models/attribute";
import AppPlugin from ".";
import { TCApiResponse } from "../../api/trackingcloud";
import { Dexie } from "dexie";
import { SyncMessage, PushSyncMessage, SyncCommandAction, SyncCommandData } from "../../database/models/clientsync";
import { StoreActions } from '../../../app/config/store';
import { ServerError, OfflineError } from "../../api/errors";
import { uuid4 } from "../../helper/uuid";
import { Model, Base } from "../../database/models/base";
import { sendErrorDiagnostics, DEFAULT_DELAY } from "../../diagnostics";
import InitialLoading from "../../initial-loading";
import { relativeTimeThreshold } from "moment";

export default class Synchronizer
{
    private app: AppPlugin;
    private scheduledTimeout: number;
    private readonly exemptPullResponseTables = {'timestamp': true};
    private isInitialPull = false;
    private nextPullTime: Date;
    private lastProcessedPullTime: Date;
    public get isSynchronizerOverlayVisible() {
        return this.app.store.getters.isSynchronizerOverlayVisible;
    }

    public set isSynchronizerOverlayVisible(value: boolean) {
        this.app.store.commit(StoreActions.IS_SYNCHRONIZER_OVERLAY_VISIBLE, value);
    }

    constructor(app: AppPlugin)
    {
        this.app = app;
    }
    
    private getLastSync(): PromiseLike<LastSynchronization>
    {
        return <any>this.app.db.Attribute.get(AttributeType.LAST_SYNCHRONIZATION);
    }

    private setLastSync(timestamp: Date)
    {
        if (timestamp != null)
        {
            return this.app.db.Attribute.put({
                type: AttributeType.LAST_SYNCHRONIZATION,
                data: timestamp,
                createdAt: new Date()
            });
        }
    }

    public pullOne<T extends Model>(name: string, pk: number): PromiseLike<T>
    {
        let aliases = this.app.db.tableAliases;
        if (name in aliases == false)
        {
            for (let fullName in aliases)
            {
                if (aliases[fullName].name == name)
                {
                    name = fullName;
                    break;
                }
            }
        }
        
        return this.app.api.call(this.app.config.clientsync.endpoints.pull_one, {
            data: { pk, name },
            authToken: this.app.userData.token,
        }).then((result) =>
        {
            return Object.assign(new this.app.db[name].schema.mappedClass, result);
        });
    }
    
    public async pull(queueNext = true, options: {postProgress?: boolean, updateConstance?: boolean} = {}) {
        let isSuccess = true;
        let lastSyncTimestamp: Date;
        const args: {[key: string]: Object} = {};

        try {

            // Flush cancels next pull
            await this.flush();
            const lastSync = await this.getLastSync();
            this.isInitialPull = lastSync == null;

            this.isSynchronizerOverlayVisible = this.isInitialPull;
            this.app.store.commit(StoreActions.SET_SYNCHRONIZATION_STATE, true);
            
            if (lastSync != null && lastSync.data != null) {
                args.modified_at = lastSync.data;
            }

            if (this.isInitialPull || (options && options.updateConstance)) {
                await this.updateConstance();
            }

            const response = await <PromiseLike<ClientSyncPullResponse>>this.app.api.call(this.app.config.clientsync.endpoints.pull, {
                data: args,
                authToken: this.app.userData.token as string,
            });

            lastSyncTimestamp = new Date(response.timestamp);
            await this.processPullResponse(response, options.postProgress);
            
            this.app.store.commit(StoreActions.SET_SYNCHRONIZATION_STATE, false);
            this.lastProcessedPullTime = this.nextPullTime;
        } catch (error) {
            isSuccess = false;

            if (error instanceof OfflineError) {
                this.app.toasts.show(
                    this.app.i18n.ugettext('Client sync failed (offline)'), {
                        duration: 5000,
                        icon: 'fas fa-plug has-text-warning'
                    }
                );
            }
            else if (error instanceof ServerError) {
                // Certain HTTP error codes are intentionally hidden or allow continuing normally
                if (!this.app.isBlockingHttpStatusCode(error.status)) {
                    return;
                }

                this.app.toasts.show(
                    this.app.i18n.ugettext('Client sync failed! Please try again. (server error)'), {
                        duration: 5000,
                        icon: 'fas fa-times has-text-danger'
                    }
                );
            }
            else {
                this.app.toasts.show(
                    this.app.i18n.ugettext('Client sync failed! (unknown error)'), {
                        duration: 5000,
                        icon: 'fas fa-times has-text-danger'
                    }
                );
            }

            sendErrorDiagnostics('clientsync.pull-error', DEFAULT_DELAY, error, {
                queueNext,
                lastSyncTimestamp,
                args
            });

        } finally {
            if (isSuccess) {
                await this.setLastSync(lastSyncTimestamp);
                this.app.store.commit(StoreActions.SET_LAST_SYNCHRONIZATION_ID, lastSyncTimestamp.toISOString());
                await this.processCommands();
                await this.removeOfflineInstances();
            }
            
            this.app.store.commit(StoreActions.SET_SYNCHRONIZATION_STATE, false);
            this.tryQueueNextPull(queueNext);
            
            await this.updateMessageQueueSize();
            await this.updateUser();
        }
    }

    public updateUser() {
        return Promise.all([
            this.app.db.User.get(this.app.userData.id as number),
            this.app.db.UserAccount.where('user_id').equals(this.app.userData.id).first(),
        ]).then(([user, userAccount]) =>
        {
            user.account = userAccount;
            this.app.store.commit(StoreActions.SET_USER, user);
        });
    }

    private async updateMessageQueueSize() {
        this.app.store.commit(StoreActions.SET_SYNCHRONIZATION_QUEUE_SIZE, await this.app.db.SyncMessage.count());
    }

    private tryQueueNextPull(queueNext: boolean)
    {
        if (queueNext)
        {
            this.cancelScheduledPull();

            this.scheduledTimeout = window.setTimeout(() => this.pull(), this.app.config.clientsync.interval);                        
            
            this.nextPullTime = new Date(new Date().getTime() + this.app.config.clientsync.interval);
            if (this.app.config.logging.clientsync)
            {
                console.info(`[ClientSync] Next sync in ${this.app.config.clientsync.interval / 1000} seconds (🕐 ${this.nextPullTime.toLocaleTimeString()}).`);
            }
        }
    }

    private async processCommands(): Promise<void> {
        const commands = await this.app.db.SyncCommand.toArray();
        const promises: PromiseLike<any>[] = [];
            
        for(const cmd of commands) {
            switch(cmd.action) {
                case SyncCommandAction.REMOVE_INSTANCE:
                    let data: SyncCommandData = JSON.parse(cmd.data);
                    let table = (this.app.db as any)[data.name] as Dexie.Table<any, string>;
                    if (table != null)
                    {
                        promises.push(table.delete(data.key));
                    }
                    break;
                case SyncCommandAction.UPDATE_CONSTANCE:
                    promises.push(this.updateConstance());
                    break;
                case SyncCommandAction.FULL_RESET:
                    // Don't cause an infinite sync loop
                    if (!this.isInitialPull)
                    {
                        indexedDB.deleteDatabase(this.app.db.name);
                        location.reload();
                    }
                    break;
                case SyncCommandAction.ACTION_APP_UPDATE:
                    // Not implemented
                    break;
                default:
                    console.error(`Unsupported sync command action "${cmd.action}", data:`, cmd.data);
            }
        }
            
        await Promise.all(promises);
        return this.app.db.SyncCommand.bulkDelete(commands.map(o => o.id));
    }

    public async updateConstance() {
        const config = await this.app.api.call('clientsync_constance');
        await this.app.db.Attribute.put({
            type: AttributeType.CONSTANCE_CONFIG,
            data: config,
            createdAt: new Date()
        });

        return config;
    }

    private async removeOfflineInstances()
    {
        const instances = await this.app.db.OfflineModelInstance.toArray()
 
        let promises: Dexie.Promise<number>[] = [];

        for (let instance of instances) {
            promises.push(
                (this.app.db[instance.table] as Dexie.Table<Model, any>).where('id').equals(instance.key).and(o => o.is_offline).delete()
            );
        }

        const counts = await Promise.all(promises);
        await this.app.db.OfflineModelInstance.bulkDelete(instances.map(o => o.id));
    }

    public async putOfflineInstance(table: Dexie.Table<Model, any>, instance: Model): Dexie.Promise<number> {
        if (instance.id == null) {
            delete instance.id;
        }

        instance.is_offline = true;

        if (instance instanceof Base) {
            const now = new Date();

            if (instance.created_at == null) {
                instance.created_at = now;
            }
            instance.modified_at = now;
        }

        const id = await table.put(instance);
        instance.id = id;

        await this.app.db.OfflineModelInstance.add({
            table: table.name,
            key: id,
            created_at: new Date()
        });

        return id;
    }

    private preProcessPushSyncMessage(data: PushSyncMessage) {
        if (data.timestamp == null) {
            data.timestamp = new Date();
        }

        if (data.user_id == null) {
            // At this point the user is authenticated
            data.user_id = this.app.userData.id as number;
        }

        if (data.uuid == null) {
            data.uuid = uuid4();
        }
    }

    public async push(data: PushSyncMessage | PushSyncMessage[]): Promise<SyncResult> {
        let result: SyncResult = SyncResult.SUCCESS;

        if (!Array.isArray(data)) {
            data = [data];
        }

        for(let item of data) {
            this.preProcessPushSyncMessage(item);
        }

        const ids = await this.app.db.SyncMessage.bulkAdd(data as any);
        // Flush cancels next pull, restored at the end of this promise.
        const flushResult = await this.flush();

        if (flushResult instanceof ClientSyncActionNotAllowed) {
            result = SyncResult.ACTION_NOT_ALLOWED;
        } else if (flushResult instanceof ServerError) {
            result = SyncResult.ERROR;
        } else if (flushResult instanceof OfflineError) {
            result = SyncResult.OFFLINE;
        } else if (flushResult == null) {
            result = SyncResult.SUCCESS;
        } else {
            result = SyncResult.ERROR;
        }
        await this.pull(true);
        await this.updateMessageQueueSize();
        return result;
    }

    private async flush() {
        this.cancelScheduledPull();
        let messages: SyncMessage[] = [];
        let result = undefined;

        try {
            messages = await this.app.db.SyncMessage.orderBy('timestamp').toArray();

            // Do not send an empty push
            if (messages.length == 0) {
                return;
            }
            
            const response = await this.app.api.call(this.app.config.clientsync.endpoints.push, {
                data: {
                    messages: messages
                }
            }) as ClientSyncFlushResponse;
            
            const $toasts = this.app.toasts;
            
            if (response.messages.length > 0) {
                for (const message of response.messages) {
                    if (message.type === ClientSyncFlushMessageType.NOT_ALLOWED) {
                        result = new ClientSyncActionNotAllowed();
                        const toast = await $toasts.error(
                            message.content, {
                                duration: 0 // infinite display until user dismisses it
                            }
                        );
                            
                        // Show next after previous one is closed
                        await toast.untilClosed();
                    }
                }
            }

            await this.app.db.SyncMessage.bulkDelete(messages.map(o => o.id));
            return result;
        } catch (error) {
            if (error instanceof ServerError) {
                // Certain HTTP error codes are intentionally hidden or allow continuing normally
                if (!this.app.isBlockingHttpStatusCode(error.status)) {
                    return undefined;
                }

                // TC failed to process sync message, cancel
                await this.app.db.SyncMessage.bulkDelete(messages.map(o => o.id));

                this.app.toasts.show(
                    this.app.i18n.pgettext(
                        'maintenance.client',
                        'Error while synchronizing data - please try again.'
                    ), {
                        icon: 'fa fa-times has-text-danger'
                    }
                );
            }
        
            sendErrorDiagnostics('clientsync.flush-error', DEFAULT_DELAY, error, {
                messages: messages.map(m => m.asLoggableObject())
            });
            return error;
        }
    }

    private cancelScheduledPull() {
        // If there's a pull time and it isn't the one that just completed, i.e. next one is not yet scheduled
        if (this.nextPullTime != null && this.lastProcessedPullTime != null && this.lastProcessedPullTime.getTime() != this.nextPullTime.getTime()) {
            console.log(`[ClientSync] 🕐 ${this.nextPullTime.toLocaleTimeString()} sync cancelled.`);
            this.nextPullTime = null;
        }
        window.clearTimeout(this.scheduledTimeout);
    }

    private processPullResponse(response: ClientSyncPullResponse, postProgress = false) {
        const roughTableCount = Object.keys(response).length;
        if (postProgress) {
            InitialLoading.postProgressMessage(
                `Processing ${roughTableCount} synchronization items...`,
                0.1,
            );
        }
        const stats: {count: number} = {count: 0};
        const progressPerTable = (1 - InitialLoading.progress) / roughTableCount;
        const incrementTableProgress = () => {
            if (postProgress) {
                InitialLoading.postProgressMessage(null, progressPerTable);
            }
        };

        return this.app.db.transaction('rw', this.app.db.tables, () =>
        {
            const promises: Dexie.Promise<any>[] = [];

            const processedTables: {[key: string]: boolean} = {};

            for (const tableName in response) {
                const objects = response[tableName];

                if (!this.app.db.hasOwnProperty(tableName)) {
                    incrementTableProgress();
                    continue;
                }
                
                // If the response contains key-value pairs where the value is not an array, skip it
                // @TODO pull request really should wrap the tables in a property instead of having them on the root level
                if (!Array.isArray(objects)) {
                    if (this.app.config.logging.clientsync) {
                        console.log(`[ClientSync] Skipping table "${tableName}" (result is not an array)`, objects);
                    }
                    incrementTableProgress();
                    continue;
                }
                
                let table = (this.app.db as any)[tableName] as Dexie.Table<any, any>;

                //Pre-process data & collect stats for debug
                for(let i = 0; i < objects.length; i++) {
                    let object = this.preProcessInstance(table, objects[i]);
                    objects[i] = object;
                    if (stats[table.name] == null)
                    {
                        stats[table.name] = 0;
                    }
                    stats[table.name]++;
                    stats.count++; 
                }
                
                const promise = table.bulkPut(objects);
                promise.catch((error) =>
                {
                    console.error(`[ClientSync]: Bulk put failed for table ${table.name}`, {rows: objects});
                    console.error(error);
                });

                promises.push(promise);

                processedTables[tableName] = true;
                incrementTableProgress();
            }

            if (this.app.config.logging.clientsync)
            {
                console.info(`[ClientSync] Updated ${stats.count} objects.`, stats);
            }

            for (let tableName in response)
            {
                if (tableName in processedTables === false && tableName in this.exemptPullResponseTables === false)
                {
                    if (this.app.config.logging.clientsync)
                    {
                        console.warn(`[ClientSync] Unknown table "${tableName}" (not recognized)`);
                    }
                }
            }
            
            return Promise.all(promises).then(() =>
            {
                return true;
            }).catch((error) =>
            {
                console.error(`[ClientSync] Failed to process pull response. See the possible errors above for more information.`);
                return false;
            });
            
        });
    }

    public preProcessInstance(table: Dexie.Table<any, any>, instance: Model)
    {
        if (table.schema.mappedClass != null)
        {
            instance = Object.assign(new (table.schema.mappedClass as {new ()})(), instance) as Model;
            instance.preProcessInstance();
        }
        
        return instance;
    }

    public async createOrUpdateOfflineInstance<T extends Model>(table: Dexie.Table<T, any>, item: T, isModification = true) {
        const promises: PromiseLike<any>[] = [];
        
        item.is_offline = !item.is_saved;
        item.offline_message_uuid = item.offline_message_uuid != null ? item.offline_message_uuid : uuid4();
        promises.push(this.putOfflineInstance(table, item));
        
        // if the previously saved item is offline, remove its message
        if (item.is_offline && isModification) {
            promises.push(this.app.db.SyncMessage.where('uuid').equals(item.offline_message_uuid).delete());
        }

        await Promise.all(promises);
    }
}

export interface ClientSyncPullResponse extends TCApiResponse
{
    timestamp: string;
}

export enum SyncResult
{
    SUCCESS = 'success',
    ERROR = 'error',
    OFFLINE = 'offline',
    ACTION_NOT_ALLOWED = 'action-not-allowed'
}

export interface ClientSyncFlushResponse extends TCApiResponse {
    message_ids: number[];
    messages: {content: string, type: ClientSyncFlushMessageType}[];
}

export enum ClientSyncFlushMessageType {
    NOT_ALLOWED = 'action-not-allowed'
}

export class ClientSyncActionNotAllowed extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, ClientSyncActionNotAllowed.prototype);
    }
}

