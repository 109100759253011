var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("div", { staticClass: "header" }, [
        _vm._v(
          _vm._s(_vm.$pgettext("Dashboard", "Invoicing for the current month"))
        )
      ]),
      _vm._v(" "),
      _c("transition-group", { attrs: { name: "data" } }, [
        _vm.didFail
          ? _c("div", { key: "error", staticClass: "error-message" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$ugettext("Failed to load data. Are you offline?"))
                )
              ])
            ])
          : _vm.isLoaded
          ? _c("div", { key: "items", staticClass: "items" }, [
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "value-display" }, [
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      _vm.data.target_invoicing == null
                        ? [_c("i", [_vm._v(_vm._s(_vm.$ugettext("N/A")))])]
                        : [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.asFormatted(_vm.data.target_invoicing)
                                ) +
                                "\n                    "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "unit" }, [
                    _vm._v(_vm._s(_vm.data.currency))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.$pgettext("Dashboard", "Target")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "value-display" }, [
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      _vm.data.realised_invoicing == null
                        ? [_c("i", [_vm._v(_vm._s(_vm.$ugettext("N/A")))])]
                        : [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.asFormatted(_vm.data.realised_invoicing)
                                ) +
                                "\n                    "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "unit" }, [
                    _vm._v(_vm._s(_vm.data.currency))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.$pgettext("Dashboard", "Realised")))
                ])
              ])
            ])
          : _c("div", { key: "spinner", staticClass: "loading-icon" }, [
              _c("i", {
                staticClass: "fas fa-spinner fa-spin",
                attrs: { "aria-hidden": "true" }
              })
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }