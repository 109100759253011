var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("main", { staticClass: "menu-block" }, [
      _c("header", [
        _c(
          "span",
          { staticClass: "icon" },
          [
            _vm._t("icon", function() {
              return [
                _c("i", {
                  staticClass: "fa fa-user",
                  attrs: { "aria-hidden": "true" }
                })
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("footer", [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }